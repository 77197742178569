<div class="container">
  <mat-card class="mt-3">
    <mat-card-content>
      <div style="display: flex; flex-direction: row; justify-content: space-between;" class="mb-5">
        <h1>{{'common.about_cargoflow' | translate}}</h1>
      </div>

      <button (click)="checkUpdates()" mat-flat-button color="primary" style="width: 100%">{{'common.check_for_updates' | translate}}</button>
      <br>
      <br>
      <a target="_blank" href="https://docs.google.com/document/d/1XB993IyDqZTsOdC2zNlPxJ56ZoZO5cTy/edit?usp=sharing&ouid=100657213964398701096&rtpof=true&sd=true"
        mat-flat-button color="primary" style="width: 100%">{{'common.documentation' | translate}}</a>
      <br>
      <br>
      <div class="px-5 py-2 text-center">
        <a mat-flat-button color="primary" href="https://stats.uptimerobot.com/43xPOhzOrx" target="_blank"
          class="text-base text-gray-500 hover:text-gray-900">{{'common.status' | translate}}</a>
      </div>
      <br>
      <br>

      <div class="px-5 py-2 text-center">
        <a mat-flat-button color="primary" href="" class="text-base text-gray-500 hover:text-gray-900">{{'common.reload' | translate}}</a>
      </div>
      <br>
      <br>

      <!-- <button (click)="changeLog()" mat-flat-button color="primary" style="width: 100%">Changelog</button> -->

      <div class="d-flex justify-content-center mt-4">
        <p>Build {{versions.hash}} {{'common.from' | translate}} {{versions.date | date: 'yyyy-MM-dd HH:mm'}}</p>
      </div>

      <!-- <pre>{{authService.getUsername()}}</pre> -->
      <div class="d-flex justify-content-center mt-4"
        *ngIf="authService.isAuthenticatedPlatformAdmin() || authService.getUsername()?.includes('Gomes')">
        <a mat-flat-button color="primary" routerLink="/main/carrier/debug"
          class="text-base text-gray-500 hover:text-gray-900">{{'common.debug' | translate}}</a>
      </div>

    </mat-card-content>

  </mat-card>
  <!--
    <mat-card class="mt-3">
        <mat-card-content>

            <div style="display: flex; flex-direction: row; justify-content: space-between;" class="mb-5">
                <h1>Hilfe-Videos für Spediteure</h1>
            </div>

            <h5>Eigene Kunden erlauben Sendungen mit Sendungserfassungsmaske einzugeben</h5>
            <div style="position: relative; padding-bottom: 64.51612903225806%; height: 0;"><iframe
                    src="https://www.loom.com/embed/bd0341702fd84b28a00e35e15ed7c9da?sid=ed14f697-0f7c-4bcf-bab5-62171eed28d5"
                    frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>

            <h5>Eigene Kunden Per Schnittstelle anbinden</h5>
            <div style="position: relative; padding-bottom: 74.3801652892562%; height: 0;">
                <iframe
                    src="https://www.loom.com/embed/95464ddc737844b3ab28d09a86763e3f?sid=7f61b6c0-d124-432f-896b-0eb340499c35"
                    frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                </iframe>
            </div>

        </mat-card-content>
    </mat-card> -->
<!--
  <mat-card class="mt-3">
    <mat-card-content>
      <div style="display: flex; flex-direction: row; justify-content: space-between;" class="mb-5">
        <h1>Road Map</h1>
      </div>

      <span class="badge bg-success">(Q3 2023)</span>
      <ul>
        <li>{{'common.custom_pricing_models' | translate}}</li>
        <li>{{'common.signatures_on_pickup_and_delivery' | translate}} </li>
        <li>{{'common.region_limitation' | translate}} </li>
        <li>{{'common.customer_address_book' | translate}} </li>
        <li>{{'common.driver_card_with_real_time_coordinates' | translate}} </li>
        <li>{{'common.international_addresses' | translate}} </li>
        <li>{{'common.multiple_currencies' | translate}} </li>
        <li>{{'common.calculation_of_truck_utilization' | translate}} <span class="badge bg-success">Done</span></li>
        <li>{{'common.route_planning' | translate}} <span class="badge bg-success">Done</span></li>
        <li>{{'common.marketplace' | translate}} <span class="badge bg-success">Done</span></li>
        <li>{{'common.hub_management' | translate}} <span class="badge bg-success">Done</span></li>
        <li>{{'common.shipment_images_pickup_delivery' | translate}} <span class="badge bg-success">Done</span></li>
        <li>{{'common.public_tracking_page' | translate}} <span class="badge bg-success">Done</span></li>
      </ul>

      <span class="badge bg-secondary">(Q1 2024)</span>
      <ul>
        <li>{{'common.empty_container_network' | translate}} </li>
        <li>Universal Tracking Label </li>
        <li>{{'common.customs_clearance' | translate}} </li>
      </ul>

    </mat-card-content>

  </mat-card> -->
</div>


<div class="container">

  <div class="d-flex justify-content-center mt-4">
    <p class="mt-5">© 2023 Neptun Design GmbH. All rights reserved</p>
  </div>

</div>
