<div style="display: flex; flex-direction: row; justify-content: end;">

    <div>
        <button mat-icon-button color="primary" (click)="refresh()">
            <mat-icon>refresh</mat-icon>
        </button>
    </div>
</div>

<table *ngIf="!!invoices && invoices.length > 0" style="width: 100%" class="table table-custom">
    <thead>
        <td>{{'common.number' | translate}}</td>
        <td>{{'common.status' | translate}}</td>
        <td>{{'common.client' | translate}}</td>
        <td>{{'common.sum' | translate}}</td>
        <td>
            {{'common.build' | translate}}{{'common.creation_date' | translate}} <br> {{'common.sent_on' | translate}} <br> {{'common.payable_by' | translate}}
        </td>
        <td></td>
    </thead>

    <tbody>
        <tr *ngFor="let item of invoices">
            <td [attr.data-label]="'common.number' | translate">
                <a routerLink="/main/carrier/facturation/invoices/{{item.uuid}}">
                    {{item.number}}
                </a>
            </td>
            <td [attr.data-label]="'common.status' | translate" style="vertical-align: bottom;">
                <a [routerLink]="[]" (click)="editStatusInvoice(item)" class="text-center">

                    <div class="alert alert-success" role="alert" *ngIf="item.status==invoiceStatus.PAID">
                        {{item.status | invoiceStatusPipe}}
                    </div>

                    <div class="alert alert-warning" role="alert" *ngIf="item.status==invoiceStatus.DRAFT">
                        {{item.status | invoiceStatusPipe}}
                    </div>
                    <div class="alert alert-primary" role="alert" *ngIf="item.status==invoiceStatus.SENT">
                        {{item.status | invoiceStatusPipe}}
                    </div>

                    <div class="alert alert-dark" role="alert" *ngIf="item.status==invoiceStatus.CANCELLED">
                        {{item.status | invoiceStatusPipe}}
                    </div>
                </a>
            </td>
            <td [attr.data-label]="'common.client' | translate">
                <a *ngIf="item.client_object" routerLink="/main/carrier/clients/{{item.client_object.uuid}}/details" >
                    <span *ngIf="item.client_object.company">
                        {{item.client_object.company}}
                    </span>
                    <span *ngIf="!item.client_object.company">{{item.client_object.first_name}} {{item.client_object.last_name}}</span>
                </a>
            </td>

            <td [attr.data-label]="'common.sum' | translate">{{ownOrganization?.default_currency}} {{item.total_price_with_vat | number : '1.2-2' }}</td>
            <td>
                {{item.created_at | date: "yyyy-MM-dd"}}
                <br>
                {{item.invoice_date}}
                <br>
                {{item.pay_until_date}}
            </td>
            <td style="text-align: end;">

                <!-- <button mat-icon-button (click)="editStatusInvoice(item)">
                    <mat-icon>edit_attributes</mat-icon>
                </button> -->

                <!-- <button mat-icon-button (click)="editInvoice(item)">
                    <mat-icon>edit</mat-icon>
                </button> -->
                <div class="d-flex justify-content-evenly justify-content-sm-end">
                    <button mat-icon-button (click)="downloadInvoice(item)">
                        <mat-icon>download</mat-icon>
                    </button>
                    <button mat-icon-button (click)="deleteInvoice(item)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </td>
        </tr>
    </tbody>
</table>

<div *ngIf="!!invoices && invoices.length == 0" class="alert alert-info">
    {{'carrier_dashboard.invoicing.no_invoice' | translate}}
</div>

<!-- <pre>{{invoices | json}}</pre> -->
