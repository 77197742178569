<div class="container">
  <mat-card class="mt-3">
    <mat-card-content>
      <div style="display: flex; flex-direction: row; justify-content: space-between;">
        <h1>{{'sender.marketplace.main_title' | translate}} </h1>
        <div style="display: flex; align-items: center;">
          <mat-spinner [diameter]="20" style="margin: 12px" *ngIf="isLoading"> </mat-spinner>
          <button mat-icon-button color="primary" (click)="refresh()">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
      </div>

      <div class="col-12 text-center col-md-5 mx-auto">
        <div class="mt-2 mb-3 d-flex">
          <a routerLink="/main/carrier/market-place" class="btn-primary green primary-hover-animation w-100">{{'sender.marketplace.foreign_shipments' | translate}} <mat-icon>remove_red_eye</mat-icon></a>
        </div>
        <div class="text-center mb-3 d-flex">
          <a routerLink="/main/shipper/wizard" class="btn-primary green primary-hover-animation w-100"> {{'sender.marketplace.new_shipments' | translate}} <mat-icon>add_to_photos</mat-icon></a>
        </div>
      </div>

      <br>



      <div class="text-center mt-5" *ngIf="!isLoading && shipments.length == 0">
        <div class="alert alert-info">{{'common.no_shipment' | translate}} </div>
      </div>

      <table class="table table-custom" style="width: 100%" *ngIf="!!shipments && shipments.length > 0">
        <thead>
          <td>{{'common.number' | translate}}</td>
          <td>{{'common.from' | translate}} </td>
          <td>{{'common.to' | translate}} </td>
          <td>{{'common.views' | translate}} </td>
          <td>{{'common.run_date' | translate}} </td>
        </thead>

        <tbody>
          <tr *ngFor="let shipment of shipments">
            <td [attr.data-label]="'common.number' | translate">
              <a routerLink="/main/shipper/shipments/{{shipment.uuid}}">
                {{shipment.number | shipmentNumberPipe }}
              </a>
            </td>
            <td [attr.data-label]="'common.from' | translate">
              {{shipment.pickup_address }}
              <br>
              {{shipment.pickup_zip }} {{shipment.pickup_location }}
              <br>
              {{shipment.pickup_date }}
            </td>

            <td [attr.data-label]="'common.to' | translate">
              {{shipment.delivery_address }}
              <br>
              {{shipment.delivery_zip }} {{shipment.delivery_location }}
              <br>
              {{shipment.delivery_date }}
            </td>
            <td [attr.data-label]="'common.views' | translate">
              {{shipment.views}}
            </td>
            <td [attr.data-label]="'common.run_date' | translate">
              {{shipment.end_date | date: 'yyyy-MM-dd HH:mm'}}
            </td>
          </tr>
        </tbody>
      </table>

    </mat-card-content>
  </mat-card>
</div>
