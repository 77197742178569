<mat-card class="mt-3" *ngIf="(usersService.ownUser$ | async)?.permission_see_pricing">

  <mat-card-content *ngIf="transactions">
    <div *ngFor="let transaction of transactions">

      <div style="display: flex; flex-direction: row; justify-content: space-between;">
        <h5 translate>common.price</h5>
        <div style="display: flex; align-items: center;">

          <button mat-icon-button color="primary" (click)="openEditPricingModal(transaction)"
            *ngIf="transaction.kind == shipmentTransactionKind.OWN_CLIENT">
            <mat-icon>edit</mat-icon>
          </button>

          <button mat-icon-button color="primary" (click)="openAddSurchargeModal(transaction)">
            <mat-icon>add_circle</mat-icon>
          </button>
        </div>

      </div>

      <div class="alert alert-info" *ngIf="transaction.kind != shipmentTransactionKind.OWN_CLIENT">
        {{'common.flat_marketplace_price' | translate}}
        <span *ngIf="transaction.kind">
          ({{transaction.kind | transactionTypePipe}})
        </span>
      </div>

      <ul class="co-simple-list">

        <li *ngIf="transaction.buyer_name">
          <span style="display: flex; align-items: center; ">
            <span class="me-1">
              {{'common.contractor' | translate}}:
            </span>
            <a routerLink="/main/carrier/clients/{{transaction.buyer_uuid}}/details">
              {{transaction.buyer_name}}
            </a>
          </span>
        </li>

        <li *ngIf="transaction.seller_name">
          <span style="display: flex; align-items: center; ">
            <span class="me-1">
              {{'common.consignor' | translate}}:
            </span>
            <a routerLink="/main/carrier/clients/{{transaction.seller_uuid}}/details">
              {{transaction.seller_name}}
            </a>
          </span>
        </li>
        <li>
          {{'common.invoice' | translate}}:
          <a routerLink="/main/carrier/facturation/invoices/{{transaction.invoice_object.uuid}}"
            *ngIf="transaction.invoice">
            {{transaction.invoice_object.number }}
          </a>
          <span *ngIf="!transaction.invoice"> {{'common.not_yet_invoiced' | translate}}</span>
        </li>
      </ul>

      <table style="width: 100%" class="table table-striped">
        <thead>
          <td>{{'common.position' | translate}}</td>
          <td translate>common.sum</td>
        </thead>

        <tbody>

          <tr>
            <td translate>common.transport_price</td>
            <td>{{ownOrganization?.default_currency}} {{transaction.base_price | number: '1.2-2'}}</td>
          </tr>

          <tr *ngFor="let surcharge of transaction.surcharges">
            <td>{{'common.subcharge_preis' | translate}}</td>
            <td>{{ownOrganization?.default_currency}} {{surcharge.price | number: '1.2-2'}}</td>
          </tr>

          <!-- <tr *ngFor="let option of transaction.options">
            <td>{{'common.congestion_preis' | translate}}</td>
            <td>{{ownOrganization?.default_currency}} {{option.price | number: '1.2-2'}}</td>
          </tr> -->

          <tr>
            <td style="font-weight: bold;">{{'carrier_dashboard.shipment_price' | translate}} ({{'common.net' | translate}})</td>
            <td style="font-weight: bold;">{{ownOrganization?.default_currency}} {{transaction.total_price | number: '1.2-2'}}</td>
          </tr>

          <tr>
            <td translate>common.value_added_tax</td>
            <td>{{ownOrganization?.default_currency}} {{transaction.total_vat | number: '1.2-2'}}</td>
          </tr>

          <tr>
            <td style="font-weight: bold;">{{'carrier_dashboard.shipment_price' | translate}} ({{'common.gross' | translate}})</td>
            <td style="font-weight: bold;">{{ownOrganization?.default_currency}} {{transaction.total_price_with_vat | number: '1.2-2'}}</td>
          </tr>

        </tbody>
      </table>
      <!-- <pre>{{transaction | json}}</pre> -->
    </div>

  </mat-card-content>
</mat-card>
