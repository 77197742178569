<div class="container mt-3">

  <div class="row mb-5" *ngIf="withClientSelection">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title" translate>common.client</h5>
          <app-client-selector [selectedClientUUID]="shipmentForm.value.client" [isRequired]="true"
            (onSelectedClient)="onSelectedClient($event)"></app-client-selector>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-5">
    <div class="col-sm-6 mb-3 mb-sm-0">
      <app-location-form [shipmentForm]="shipmentForm" [type]="'pickup'"></app-location-form>
    </div>
    <div class="col-sm-6">
      <app-location-form [shipmentForm]="shipmentForm" [type]="'delivery'"></app-location-form>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <ng-container *ngFor="let packetForm of packetItemForms.controls; index as index">
        <div class="mb-5">
          <app-shipment-packets [index]="index" [packetForm]="packetForm"
            [number_of_packets]="packetItemForms.controls.length"
            (deletePacket)="removePacketItem($event)"></app-shipment-packets>
        </div>
      </ng-container>
    </div>
    <div class="d-flex justify-content-center mb-4">
      <button class="btn-primary green primary-hover-animation" (click)="addPacketItem()">
        {{'carrier_dashboard.add_another_cargo' | translate}}
        <mat-icon>add_circle</mat-icon>
      </button>
    </div>
  </div>

  <app-extras-form [shipmentForm]="shipmentForm"></app-extras-form>

  <div class="mb-5 d-flex justify-content-between">
    <div>
      <div *ngIf="showPricingSelection">
        {{'common.price' | translate}}:
        <span *ngIf="isLoadingPricing">
          <div class="spinner-border spinner-border-sm" role="status">
          </div>
        </span>
        <span *ngIf="!!price && !isLoadingPricing">
          {{ price.total_price | number: '1.2-2'}} Fr.
          <span class="glyphicon glyphicon-pencil"></span>
          <button type="button" class="btn btn-default btn-sm" (click)="openModal(pricing)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle"
              viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
              <path
                d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z">
              </path>
            </svg>

          </button>
        </span>
        <span *ngIf="!price && !isLoadingPricing">
          -
        </span>
      </div>
    </div>

    <div class="d-flex">

      <button class="btn-primary dark primary-hover-animation me-2" (click)="resetForm()">
        {{'common.reset' | translate}}
      </button>

      <button class="btn-primary green primary-hover-animation" (click)="sendRequest()">
        {{'common.next' | translate}}
      </button>
    </div>
  </div>

  <!-- <pre>{{shipmentForm.value | json}}</pre> -->

</div>

<ng-template #pricing>
  <div class="modal-header">
    <h4 class="modal-title pull-left" translate>carrier_dashboard.price_overview</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- <pre>{{price | json}}</pre> -->
    <table *ngIf="!!price && price" style="width: 100%" class="table table-striped">
      <thead>
        <td translate>common.position</td>
        <td translate>common.sum</td>
      </thead>

      <tbody>

        <tr>
          <td translate>common.transport_price</td>
          <td>CHF {{price.base_price | number: '1.2-2'}}</td>
        </tr>

        <tr *ngFor="let option of price.options">
          <td>{{option.name}}</td>
          <td>CHF {{option.price | number: '1.2-2'}}</td>
        </tr>
        <tr>
          <td style="font-weight: bold;">{{'carrier_dashboard.shipment_price' | translate}} ({{'common.excl_vat' | translate}})</td>
          <td style="font-weight: bold;">CHF {{price.total_price | number: '1.2-2'}}</td>
        </tr>

        <tr>
          <td translate>common.value_added_tax</td>
          <td>CHF {{price.total_vat | number: '1.2-2'}}</td>
        </tr>

        <tr>
          <td style="font-weight: bold;">{{'carrier_dashboard.shipment_price' | translate}} ({{'common.incl_vat' | translate}})</td>
          <td style="font-weight: bold;">CHF {{price.total_price_with_vat | number: '1.2-2'}}</td>
        </tr>

      </tbody>
    </table>
    <button class="btn btn-primary w-100" (click)="modalRef?.hide()">{{'common.close' | translate}}</button>
  </div>
</ng-template>
