<div class="kachel position-relative overflow-hidden" *ngIf="shipment">
    <div class="client-art-wrapper">
        <div id="w-node-_09487106-a2bd-a1d2-08d9-44e23eaade19-d2dd3ff5"
            class="w-layout-layout quick-stack wf-layout-layout">
            <div id="w-node-_09487106-a2bd-a1d2-08d9-44e23eaade1a-d2dd3ff5" class="w-layout-cell z-1">
                <div class="aufraggeber-wrapper">
                    <h3 class="heading-grau">{{'common.consignor' | translate}}</h3>
                    <h3 class="heading-bold" *ngIf="shipment.orderer" (click)="openOrdererDetails()">
                        <span style="text-decoration: underline; cursor: pointer;">
                            {{shipment.orderer.name}}
                        </span>
                    </h3>
                    <h3 class="heading-bold" *ngIf="!shipment.orderer">-</h3>
                </div>
            </div>
            <div id="w-node-_09487106-a2bd-a1d2-08d9-44e23eaade1b-d2dd3ff5" class="w-layout-cell z-1">
                <div class="art">
                    <h3 class="heading-grau">{{'common.kind' | translate}}</h3>
                    <h3 class="heading-bold">{{shipment.price_mode | priceModePipe}}</h3>
                </div>
            </div>
        </div>
        <div id="w-node-_0634434f-1939-0d5e-cd1b-2041a9f6c620-d2dd3ff5"
            class="w-layout-layout quick-stack wf-layout-layout">
            <div id="w-node-_0634434f-1939-0d5e-cd1b-2041a9f6c621-d2dd3ff5" class="w-layout-cell z-1">
                <div class="abholung">
                    <h3 class="heading-grau">{{'common.pickup' | translate}}</h3>
                    <h3 class="date">{{shipment.pickup_date}}</h3>
                    <h3 class="heading-bold">{{shipment.pickup_zip}} {{shipment.pickup_location}}</h3>
                </div>
            </div>
            <div id="w-node-_0634434f-1939-0d5e-cd1b-2041a9f6c627-d2dd3ff5" class="w-layout-cell z-1">
                <div class="zustellung-wrapper">
                    <h3 class="heading-grau mb-2">{{'common.delivery' | translate}}</h3>
                    <h3 class="date">{{shipment.delivery_date}}</h3>
                    <h3 class="heading-bold">{{shipment.delivery_zip}} {{shipment.delivery_location}}</h3>
                </div>
            </div>
        </div>
        <div id="w-node-_2a9dbce8-7fc0-3ff5-cce0-55f50296fb69-d2dd3ff5"
            class="w-layout-layout quick-stack wf-layout-layout">
            <div id="w-node-_2a9dbce8-7fc0-3ff5-cce0-55f50296fb6a-d2dd3ff5" class="w-layout-cell z-1">
                <div class="stellpl-tze-wrapper">
                    <h3 class="heading-grau mb-1" translate>common.ground_spaces</h3>
                    <h3 class="heading-bold">{{ shipment.total_pallet_places | number:'1.2-2' }} {{'common.spaces' | translate}}</h3>
                </div>
            </div>
            <div id="w-node-_2a9dbce8-7fc0-3ff5-cce0-55f50296fb70-d2dd3ff5" class="w-layout-cell z-1">
                <div class="gewicht-wrapper">
                    <h3 class="heading-grau">{{'common.weight' | translate}}</h3>
                    <h3 class="heading-bold">{{shipment.total_weight}} kg</h3>
                </div>
            </div>
        </div>
        <div id="w-node-_599739f3-f6e4-821b-4776-96a4da4f1bea-d2dd3ff5"
            class="w-layout-layout quick-stack wf-layout-layout">
            <div id="w-node-_599739f3-f6e4-821b-4776-96a4da4f1beb-d2dd3ff5" class="w-layout-cell z-1">
                <div class="stellpl-tze-wrapper">
                    <h3 class="heading-grau">{{'common.price' | translate}}</h3>
                    <h3 class="preis">{{ownOrganization?.default_currency}} {{shipment.market_price | number: '1.2-2'}}</h3>
                </div>
            </div>
            <div id="w-node-_599739f3-f6e4-821b-4776-96a4da4f1bf1-d2dd3ff5" class="w-layout-cell cell-7 z-1">
                <a [routerLink]="[]"  (click)="openShipmentDetails()" class="btn-primary green primary-hover-animation ms-auto">{{'common.more_information' | translate}}</a>
            </div>
        </div>
    </div>
    <img style="height: 90%;" class="position-absolute opacity-25 m-6 z-0" src="assets/cargo_simple.png" alt="cargoflow logo">
</div>
