<div class="container">
    <mat-card class="mt-3">
        <mat-card-content>
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <h1>{{'carrier_dashboard.clients.main_title' | translate}} </h1>

                <div>
                    <button mat-icon-button color="primary" (click)="onAddClient()">
                        <mat-icon>add</mat-icon>
                    </button>
                    <button mat-icon-button color="primary" (click)="refresh()">
                        <mat-icon>refresh</mat-icon>
                    </button>
                </div>
            </div>

            <table class="table table-striped" style="width: 100%" *ngIf="!!clients && clients.length > 0">
                <thead>
                    <tr>
                        <!-- <th scope="col">{{'common.id' | translate}}</th> -->
                        <th scope="col">{{'common.firma' | translate}} </th>
                        <th scope="col">{{'common.location' | translate}} </th>
                        <th scope="col">{{'common.phone' | translate}}</th>
                        <th scope="col">{{'common.mail' | translate}}</th>
                        <th scope="col">{{'common.edited_on' | translate}}</th>
                    </tr>

                </thead>

                <tbody>
                    <tr *ngFor="let client of clients">
                        <!-- <td>{{ client.id }}</td> -->
                        <td>
                            <a [routerLink]="[]" (click)="openDetailsClient(client)">
                                <span *ngIf="client?.company; else noCompany">{{client.company}}</span>
                                <ng-template #noCompany>{{client.first_name}} {{client.last_name}}</ng-template>
                            </a>

                            <!-- <ul class="co-simple-list">
                                <li>{{ client.company}}</li>
                                <li>{{ client.first_name}}</li>
                                <li>{{ client.last_name}}</li>
                            </ul> -->
                        </td>
                        <td>{{ client.address }} {{client.zip_code }} {{client.location }}</td>
                        <td><a href="tel:{{client.phone }}">{{ client.phone }}</a></td>
                        <td><a href="mailto:{{ client.email }}">{{ client.email }}</a></td>
                        <td>{{ client.updated_at | date : "yyyy-MM-dd HH:mm"}}</td>
                        <!-- <td>

                            <button mat-icon-button (click)="editClient(client)">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button mat-icon-button (click)="deleteClient(client)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td> -->
                    </tr>
                </tbody>
            </table>

            <div class="alert alert-info" role="alert" style="width: 100%" *ngIf="!clients || clients.length == 0">
                {{'carrier_dashboard.clients.no_clients' | translate}}
            </div>
        </mat-card-content>

    </mat-card>
</div>
