<div class="container-fluid position-relative">
  <mat-card class="mt-3">
    <mat-card-content>
      <div class="text-center">
        <h1 *ngIf="type==='pickup'">{{'carrier_dashboard.shipments_to_pickup' | translate}}</h1>
        <h1 *ngIf="type==='delivery'">{{'carrier_dashboard.shipments_to_delivery' | translate}}</h1>
        <h1 *ngIf="type==='inVehicle'">{{'carrier_dashboard.shipments_in_vehicle' | translate}}</h1>
        <div style="top: -1rem; right: 0.5rem;" class="position-absolute">
          <button mat-icon-button color="primary" (click)="refresh()">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
      </div>

      <div class="text-center mt-5" *ngIf="!isLoading && shipments.length == 0">
        <div class="alert alert-info">{{'common.no_shipment' | translate}} </div>
      </div>

      <hr class="mb-2 mt-4">

      <ngx-spinner name="shipments-assigned" [fullScreen]="false"></ngx-spinner>

      <div class="row" *ngIf="!isLoading && shipments.length != 0">
        <div class="col-4">{{'common.ground_spaces' | translate}}: {{total_ground_spaces | number: '1.2-2'}} </div>
        <div class="col-4">{{'common.area' | translate}} (m2): {{total_ground_space_m2 | number: '1.2-2'}}</div>
        <div class="col-4">{{'common.weight' | translate}} (kg): {{total_weight | number: '1.2-2'}}</div>
      </div>

      <hr class="mb-4 mt-2">

      <table class="table table-custom d-none d-md-table" *ngIf="!isLoading && shipments.length != 0">
        <thead>
          <tr>
            <th>{{'common.number' | translate}}</th>
            <th>{{'common.client' | translate}}</th>
            <th>{{'common.from' | translate}} </th>
            <th>{{'common.to' | translate}} </th>
            <th>{{'common.weight' | translate}} (kg)</th>
            <th>

              <a class="btn-primary green w-100 text-decoration-none" *ngIf="['pickup', 'delivery'].includes(type)"
                [routerLink]="[]" (click)="closeShipments()">
                <span *ngIf="type=='pickup'">{{'carrier_dashboard.collective_pickup' | translate}}</span>
                <span *ngIf="type=='delivery'">{{'carrier_dashboard.collective_delivery' | translate}}</span>
              </a>

            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let shipment of shipments">
            <td name="Nummer">
              <a routerLink="/main/driver/shipments/{{shipment.uuid}}">
                {{shipment.number | shipmentNumberPipe }}
              </a>
            </td>
            <td name="Kunde">
              <span class="no-info" *ngIf="!shipment.client_object?.company">{{'common.no_info' | translate}}</span>
              <ul class="co-simple-list" *ngIf="shipment.client_object">
                <li>
                  <!-- <a routerLink="/main/carrier/clients/{{shipment.client_object.uuid}}/details">{{shipment.client_object.company
                                        }}</a> -->
                  <a [routerLink]="[]">{{shipment.client_object.company}}</a>
                </li>
                <li>
                  <!-- <a routerLink="/main/carrier/clients/{{shipment.client_object.uuid}}/details"> -->
                  <a [routerLink]="[]">
                    {{shipment.client_object.first_name }}
                    {{shipment.client_object.last_name }}
                  </a>
                </li>
              </ul>
            </td>
            <td name="Abholadress">
              <!-- TODO make sure that this works aka open google maps -->
              <a [routerLink]="[]"
                (click)="openInGoogleMaps(shipment.pickup_address, shipment.pickup_zip, shipment.pickup_location)">
                {{shipment.pickup_address }}
                <br>
                {{shipment.pickup_zip }} {{shipment.pickup_location }}
                <br>
                {{shipment.pickup_date }}
              </a>
            </td>

            <td name="Zustelladresse">
              <!-- TODO make sure that this works aka open google maps -->
              <a [routerLink]="[]"
                (click)="openInGoogleMaps(shipment.delivery_address, shipment.delivery_zip, shipment.delivery_location)">
                {{shipment.delivery_address }}
                <br>
                {{shipment.delivery_zip }} {{shipment.delivery_location }}
                <br>
                {{shipment.delivery_date }}
              </a>
            </td>
            <td name="Gesamtgewicht">
              {{shipment.total_weight }}
            </td>

            <td>
              <div class="float-center">

                <a class="btn-primary green w-100 text-decoration-none" *ngIf="['pickup', 'delivery'].includes(type)"
                  [routerLink]="[]" (click)="close(shipment)">
                  <span *ngIf="type=='pickup'">{{'driver_app.dashboard.pick_up' | translate}}</span>
                  <span *ngIf="type=='delivery'">{{'common.delivered' | translate}}</span>
                </a>

                <div class="mt-3"
                  *ngIf="(shipment.current_loc == currentLocationStatus.AT_DELIVERY_TRUCK || shipment.current_loc == currentLocationStatus.AT_PICKUP_TRUCK) && (shipment.task == shipmentTaskStatus.PICKUP_VIA_HUB || shipment.task == shipmentTaskStatus.DELIVERY_VIA_HUB)">
                  <button class="btn-primary green w-100 text-decoration-none" (click)="setAtHub(shipment)">
                    {{'common.in_hub' | translate}} "{{shipment.assigned_hub_object?.name}}" {{'common.set' |
                    translate}}</button>
                </div>

              </div>
            </td>

          </tr>
        </tbody>
      </table>
<!-- MOBILE -->
      <div class="mobile d-md-none" style="margin-bottom: 3rem;" *ngIf="!isLoading && shipments.length != 0">
        <div class="my-5" *ngFor="let shipment of shipments">

          <!-- <div style="font-size: 14px;" class="d-flex justify-content-between w-75 ps-4">
            <span>Stop 1</span>
            <span>103km</span>
            <span>1h 12min</span>
          </div> -->
          <div class="shipment-container position-relative">
            <div class="arrow-icon">
              <img src="assets/driver/arrow.svg" loading="lazy" alt="">
            </div>
            <div class="d-flex gap-2">
              <span class="fw-bold">{{'common.number' | translate}}: </span>
              <a routerLink="/main/driver/shipments/{{shipment.uuid}}">
                {{shipment.number | shipmentNumberPipe }}
              </a>
            </div>
            <!-- <hr> -->
            <div *ngIf="shipment.client_object" class="d-flex align-items-center gap-2">
              <span class="fw-bold">{{'common.client' | translate}}:</span>
              <a [routerLink]="[]">{{shipment.client_object.company}}</a>
            </div>
            <!-- <hr> -->

            <div *ngIf="type==='pickup'" class="pickup d-flex flex-column">
              <div class="d-flex justify-content-between align-items-first">
                <div>
                  <div class="d-flex gap-2 align-items-center justify-content-center">
                    <span class="fw-bold fs-4">{{'common.pickup' | translate}}</span>
                  </div>
                  <div class="d-flex flex-column">
                    <span><strong>{{'common.date' | translate}}:</strong> {{shipment.pickup_date}}</span>
                    <span><strong>{{'common.firma' | translate}}:</strong> {{shipment.pickup_company}} <span class="no-info" *ngIf="!shipment.pickup_company">{{'common.no_info' | translate}}</span></span>
                    <span><strong>{{'common.name' | translate}}:</strong> {{shipment.pickup_name}}</span>
                  </div>
                  <div>
                    <span class="fw-bold">{{shipment.pickup_address }}, {{shipment.pickup_zip }} {{shipment.pickup_location }}</span>
                  </div>
                </div>
                  <a class="round-icon-btn" [routerLink]="[]"
                    (click)="openInGoogleMaps(shipment.pickup_address, shipment.pickup_zip, shipment.pickup_location)">
                    <mat-icon>location_on</mat-icon>
                  </a>
              </div>
              <div class="d-flex justify-content-between align-content-center mt-2">
                <button class="btn-primary dark w-100"><a [href]="'tel:' + shipment.pickup_phone">{{'common.call' | translate}} {{shipment.pickup_phone}}
                    <mat-icon>phone</mat-icon></a></button>
              </div>
            </div>

            <div class="delivery d-flex flex-column" *ngIf="type==='delivery' || type==='inVehicle'">
              <div class="d-flex justify-content-between align-items-first">
                <div>
                  <div class="d-flex gap-2 align-items-center justify-content-center">
                    <span class="fw-bold fs-4">{{'common.delivery' | translate}}</span>
                  </div>
                  <div class="d-flex flex-column">
                    <span><strong>{{'common.date' | translate}}:</strong> {{shipment.delivery_date}}</span>
                    <span><strong>{{'common.firma' | translate}}:</strong> {{shipment.delivery_company}}</span>
                    <span><strong>{{'common.name' | translate}}:</strong> {{shipment.delivery_name}}</span>
                  </div>
                  <div>
                    <span class="fw-bold">{{shipment.delivery_address }}, {{shipment.delivery_zip }} {{shipment.delivery_location }}</span>
                  </div>
                </div>
                  <a class="round-icon-btn" [routerLink]="[]"
                    (click)="openInGoogleMaps(shipment.delivery_address, shipment.delivery_zip, shipment.delivery_location)">
                    <mat-icon>location_on</mat-icon>
                  </a>
              </div>
              <div class="d-flex justify-content-between align-content-center mt-2">
                <button class="btn-primary dark w-100"><a [href]="'tel:' + shipment.delivery_phone">{{'common.call' | translate}} {{shipment.delivery_phone}}
                    <mat-icon>phone</mat-icon></a></button>
              </div>
            </div>


            <!-- <hr> -->

            <span>{{'common.articles' | translate}}</span>
            <div class="expanded-items">
              <div class="d-flex justify-content-between">
                <span class="d-flex">
                  <button class="dropdown-icon" (click)="toggleItems()">
                    <mat-icon
                      [ngStyle]="{'transform': showItems ? 'rotate(180deg)' : 'rotate(0deg)'}">keyboard_arrow_down</mat-icon>
                  </button>
                  {{shipment.packets.length }} {{'common.pallets' | translate}}
                </span>
                <span></span>
                <span>{{shipment.total_weight }} Kg</span>
              </div>
              <div [ngClass]="showItems ? 'expanded': 'retracted'" class="expanded-items">
                <div class="d-flex flex-column gap-2 py-2" *ngFor="let packet of shipment.packets ; let i = index">
                  <div class="d-flex justify-content-between">
                    <span class="fw-bold">{{ i + 1 }}. {{'common.pallet' | translate}}</span>
                    <span>H{{packet.height }}cm L{{packet.length }}cm W{{packet.width }}cm</span>
                    <span>{{packet.weight }} kg</span>
                  </div>
                  <div>
                    <span>{{packet.name }}</span>
                  </div>
                </div>
                <hr>

              </div>
            </div>

            <div class="d-flex flex-column">
              <div class="d-flex justify-content-between gap-2">
                <button class="btn-primary green my-2 w-100" *ngIf="['pickup', 'delivery'].includes(type)" [routerLink]="[]"
                  (click)="close(shipment)">
                  <span *ngIf="type=='pickup'">{{'common.complete_pick_up' | translate}}</span>
                  <span *ngIf="type=='delivery'">{{'common.delivered' | translate}}</span>
                  <mat-icon>check_circle</mat-icon>
                </button>
                <!-- TODO implement this or drop it -->
                <!-- <button class="btn-primary dark my-2 w-100">{{'common.report_problem' | translate}}<mat-icon>warning</mat-icon></button> -->
              </div>
              <div class=""
                *ngIf="(shipment.current_loc == currentLocationStatus.AT_DELIVERY_TRUCK || shipment.current_loc == currentLocationStatus.AT_PICKUP_TRUCK) && (shipment.task == shipmentTaskStatus.PICKUP_VIA_HUB || shipment.task == shipmentTaskStatus.DELIVERY_VIA_HUB)">
                <button class="btn-primary green w-100" (click)="setAtHub(shipment)">
                  {{'common.in_hub' | translate}} "{{shipment.assigned_hub_object?.name}}" {{'common.set' |
                  translate}}</button>
              </div>
            </div>

          </div>
        </div>
      </div>

    </mat-card-content>
  </mat-card>
</div>