<div class="container-fluid">
    <mat-card class="mt-3">
        <mat-card-content>

            <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <h1>{{'carrier_dashboard.invoice_pipeline.main_title' | translate}} </h1>
                <div style="display: flex; align-items: center;">
                    <mat-spinner [diameter]="20" style="margin: 12px" *ngIf="isLoading"> </mat-spinner>
                    <button mat-icon-button color="primary" (click)="refresh()">
                        <mat-icon>refresh</mat-icon>
                    </button>
                </div>
            </div>


            <div class="alert alert-info bg-green-light mt-5 border-0" *ngIf="!shipmentsForInvoicing || shipmentsForInvoicing.length == 0">
                {{'carrier_dashboard.invoice_pipeline.no_shipment' | translate}}
            </div>

            <table class="table table-custom" style="width: 100%"
                *ngIf="!!shipmentsForInvoicing && shipmentsForInvoicing.length > 0">
                <thead>
                    <td>{{'common.number' | translate}}</td>
                    <td>{{'common.client' | translate}}</td>
                    <td>{{'common.from' | translate}}</td>
                    <td>{{'common.to' | translate}}</td>
                    <td></td>
                </thead>

                <tbody>
                    <tr *ngFor="let shipment of shipmentsForInvoicing">
                        <td [attr.data-label]="'common.number' | translate">
                            <a routerLink="/main/carrier/shipments/{{shipment.uuid}}">
                                {{shipment.number | shipmentNumberPipe }}
                            </a>
                        </td>
                        <td [attr.data-label]="'common.client' | translate">
                            <ul class="co-simple-list" *ngIf="shipment.client_object">
                                <a routerLink="/main/carrier/clients/{{shipment.client_object.uuid}}/details">
                                    <li>
                                        {{shipment.client_object.company}}
                                    </li>
                                    <li>
                                        {{shipment.client_object.first_name }}
                                        {{shipment.client_object.last_name }}
                                    </li>
                                </a>
                            </ul>
                        </td>
                        <td [attr.data-label]="'common.from' | translate">
                            {{shipment.pickup_address }}
                            <br>
                            {{shipment.pickup_zip }} {{shipment.pickup_location }}
                            <br>
                            {{shipment.pickup_date }}
                        </td>

                        <td [attr.data-label]="'common.to' | translate">
                            {{shipment.delivery_address }}
                            <br>
                            {{shipment.delivery_zip }} {{shipment.delivery_location }}
                            <br>
                            {{shipment.delivery_date }}
                        </td>
                        <td>
                            <div class="d-flex">
                                <a class="green-btn primary-hover-animation justify-content-center d-flex ms-auto" (click)="sendShipmentToInvoicingSystem(shipment)">
                                    <mat-icon>check</mat-icon>
                                </a>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

        </mat-card-content>

    </mat-card>
</div>
