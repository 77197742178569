<div class="container-fluid">
  <mat-card class="mt-3">
    <mat-card-content>

      <div class="d-flex flex-row justify-content-center justify-content-md-between position-relative">
        <h1 class="text-center mb-3">
          {{'carrier_dashboard.release_pipeline.main_title' | translate}}
        </h1>
        <div style="display: flex; align-items: center;">
          <mat-spinner [diameter]="20" style="margin: 12px" *ngIf="isLoading"> </mat-spinner>
          <button class="d-flex top-right-mobile" mat-icon-button color="primary" (click)="refresh()">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
      </div>

      <div class="alert alert-info text-center  mt-5" *ngIf="!shipmentsForApproval || shipmentsForApproval.length == 0">
        {{'carrier_dashboard.accept_shipments.no-shipment' | translate}}
      </div>

      <div *ngIf="!!shipmentsForApproval && shipmentsForApproval.length > 0">
        <div class="d-flex justify-content-center">
          <button class="green-btn primary-hover-animation d-flex align-items-center gap-2 justify-content-center" (click)="acceptAll()">
            {{'common.accept_all' | translate}}
            <mat-icon>thumb_up_alt</mat-icon>
          </button>
        </div>

        <table class="table table-custom w-100">
          <thead>
            <tr>
              <th>{{'common.number' | translate}}</th>
              <th>{{'common.client' | translate}}</th>
              <th>{{'common.price'| translate}}</th>
              <th>{{'common.from'| translate}}</th>
              <th>{{'common.to'| translate}}</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let shipment of shipmentsForApproval">
              <td [attr.data-label]="'common.number' | translate">
                <a routerLink="/main/carrier/shipments/{{shipment.uuid}}">
                  {{shipment.number | shipmentNumberPipe }}
                </a>
              </td>
              <td [attr.data-label]="'common.client' | translate">
                <ul class="co-simple-list" *ngIf="shipment.client_object">
                  <li>
                    <a routerLink="/main/carrier/clients/{{shipment.client_object.uuid}}/details">
                      <span *ngIf="shipment.client_object.company; else noClient">
                        {{shipment.client_object.company }}
                      </span>
                      <ng-template #noClient>
                        {{shipment.client_object.first_name }}
                        {{shipment.client_object.last_name }}
                      </ng-template>
                    </a>
                  </li>
                  <li>
                    <a routerLink="/main/carrier/clients/{{shipment.client_object.uuid}}/details">
                    </a>
                  </li>
                </ul>
                <span class="opacity-50" *ngIf="!shipment.client_object">{{'common.no_client_assingned' | translate}}</span>
              </td>
              <td [attr.data-label]="'common.price' | translate" name="Preis">
                {{ownOrganization?.default_currency}} {{shipment.total_price | pricePipe}}
              </td>
              <td [attr.data-label]="'common.from' | translate" name="Abholadresse">
                <span *ngIf="shipment.pickup_company">
                  {{shipment.pickup_company }}
                  <br>
                </span>
                <span *ngIf="shipment.pickup_name">
                  {{shipment.pickup_name }}
                  <br>
                </span>
                {{shipment.pickup_address }}
                <br>
                {{shipment.pickup_zip }} {{shipment.pickup_location }}
                <br>
                {{shipment.pickup_date }}
              </td>

              <td [attr.data-label]="'common.to' | translate" name="Zustelladresse">
                <span *ngIf="shipment.delivery_company">
                  {{shipment.delivery_company }}
                  <br>
                </span>
                <span *ngIf="shipment.delivery_name">
                  {{shipment.delivery_name }}
                  <br>
                </span>

                {{shipment.delivery_address }}
                <br>
                {{shipment.delivery_zip }} {{shipment.delivery_location }}
                <br>
                {{shipment.delivery_date }}
              </td>
              <td name="Aktionen">
                <div class="d-flex justify-content-around justify-content-md-end gap-2">
                  <a class="green-btn primary-hover-animation d-flex align-items-center justify-content-center" (click)="acceptShipment(shipment)">
                    <mat-icon>thumb_up_alt</mat-icon>
                  </a>
                  <a class="green-btn primary-hover-animation d-flex align-items-center justify-content-center" (click)="sellShipment(shipment)">
                    <!-- <mat-icon>money</mat-icon> -->
                    {{'common.sell' | translate}}
                  </a>

                </div>
              </td>

            </tr>
          </tbody>
        </table>
      </div>

      <a routerLink="/main/carrier/pipeline-dispatch" class="light-btn primary-hover-animation w-100 mx-auto">{{'common.disposition' | translate}}</a>

    </mat-card-content>

  </mat-card>
</div>
