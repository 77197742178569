<h1 mat-dialog-title>
  <span>{{'carrier_dashboard.marketplace.main_title' | translate}}</span>
</h1>
<div mat-dialog-content class="pt-2">

  <form [formGroup]="form">

    <section class="example-section mb-3">
      <label>{{'common.price_mode' | translate}}:</label>
      <mat-radio-group formControlName="price_mode">
        <mat-radio-button [value]="shipmentPriceMode.SELLER_SET">{{'common.flat_rate_in_marketplace' | translate}}</mat-radio-button>
        <mat-radio-button [value]="shipmentPriceMode.AUCTION">{{'common.auction_in_marketplace' | translate}}</mat-radio-button>
        <mat-radio-button [value]="shipmentPriceMode.BUYER_SET" *ngIf="(organizationsService.ownOrg$ | async)?.admin_permission_software_license">{{'common.assignment_to_carrier' | translate}}</mat-radio-button>
      </mat-radio-group>
    </section>

    <div *ngIf="form.value.price_mode == shipmentPriceMode.SELLER_SET">
      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>{{'common.flat_rate' | translate}} (CHF)</mat-label>
        <input matInput formControlName="price">
      </mat-form-field>

      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>{{'common.until_latest' | translate}}</mat-label>
        <mat-datetimepicker-toggle [for]="datetimePicker" matSuffix></mat-datetimepicker-toggle>
        <mat-datetimepicker #datetimePicker type="datetime"> </mat-datetimepicker>
        <input matInput formControlName="end_date" [matDatetimepicker]="datetimePicker" required autocomplete="false" />
      </mat-form-field>
      <div class="alert alert-info">
        <span>{{'common.the_shipment_enters_the_marketplace' | translate}} <i>CHF {{form.value.price | number: '1.2-2'}}</i> {{'common.and_remains_at_this_price_until_accepted' | translate}}
          <i>{{form.value.end_date | date: "yyyy-MM-dd HH:mm"}} </i>
        </span>
      </div>

    </div>

    <div *ngIf="form.value.price_mode == shipmentPriceMode.AUCTION">
      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>{{'common.starting_price' | translate}} (CHF)</mat-label>
        <input matInput formControlName="price">
      </mat-form-field>

      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>{{'common.increment_steps' | translate}} (CHF)</mat-label>
        <input matInput formControlName="price_step">
      </mat-form-field>

      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>{{'common.maximum_price' | translate}} (CHF)</mat-label>
        <input matInput formControlName="price_max">
      </mat-form-field>


      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>{{'common.until_latest' | translate}}</mat-label>
        <mat-datetimepicker-toggle [for]="datetimePicker" matSuffix></mat-datetimepicker-toggle>
        <mat-datetimepicker #datetimePicker type="datetime"> </mat-datetimepicker>
        <input matInput formControlName="end_date" [matDatetimepicker]="datetimePicker" required autocomplete="false" />
      </mat-form-field>


      <div class="alert alert-info">
        <span>{{'common.the_shipment_enters_the_marketplace' | translate}} <i>CHF {{form.value.price | number: '1.2-2'}}</i> {{'common.and_is_adjusted_in_regular_intervals_by' | translate}}
          <i> CHF {{form.value.price_step | number: '1.2-2'}}</i> {{'common.until_a_transport_company_accepts_the_shipment' | translate}}
          {{'common.the_maximum_price_is_capped_at' | translate}} <i>CHF {{form.value.price_max | number: '1.2-2'}}</i>. {{'common.if_no_carrier_accepts_the_shipment' | translate}}
          <i>{{form.value.end_date | date: "yyyy-MM-dd HH:mm"}} </i>, {{'common.the_auction_ends_and_the_shipment_is_hidden' | translate}}
          {{'common.you_then_have_the_option_to_adjust_the_prices' | translate}}
        </span>
      </div>

    </div>

    <div *ngIf="form.value.price_mode == shipmentPriceMode.BUYER_SET">
      <app-organization-selector (onSelectedOrganization)="onSelectedOrganization($event)"
        [filterOrganization]="'carrier'" [withSelf]="false"></app-organization-selector>
      <div>
        <span *ngIf="isLoadingPricing">
          <div class="spinner-border spinner-border-sm" role="status">
          </div>
        </span>
        <span *ngIf="!!carrier_price && !isLoadingPricing">
          {{'common.total_shipment_price' | translate}} ({{'common.excl_vat' | translate}}): {{ carrier_price | number: '1.2-2'}} Fr.
          <br>
          {{'common.total_shipment_price' | translate}} ({{'common.incl_vat' | translate}}): {{ carrier_price_with_vat | number: '1.2-2'}} Fr.
        </span>
        <span *ngIf="!carrier_price && !isLoadingPricing">
          -
        </span>
      </div>
    </div>

  </form>

  <!-- <pre>{{form.value | json}}</pre> -->

</div>
<div class="mb-2" mat-dialog-actions style="display: flex; justify-content: space-evenly">
  <button class="btn-primary light primary-hover-animation" (click)="cancel()">{{'common.cancel' | translate}}</button>
  <button class="btn-primary green primary-hover-animation" cdkFocusInitial (click)="save()" *ngIf="form.value.price_mode != shipmentPriceMode.BUYER_SET">{{'common.publish' | translate}}</button>
  <button class="btn-primary green primary-hover-animation" cdkFocusInitial (click)="save()" *ngIf="form.value.price_mode === shipmentPriceMode.BUYER_SET">{{'common.requests' | translate}}</button>
</div>
