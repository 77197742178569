import { Component, OnInit } from '@angular/core';
import { ClientsService } from 'projects/webapp/app/services/clients.service';
import { ExportService } from 'projects/webapp/app/services/export.service';
import { LanguageService } from 'projects/webapp/app/services/language.service';
import { UsersService } from 'projects/webapp/app/services/users.service';
import { downloadFile } from 'projects/webapp/app/utils';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss']
})
export class ExportComponent implements OnInit {

  bsInlineValue = new Date();
  bsInlineRangeValue: Date[];
  maxDate = new Date();

  constructor(
    public clientService: ClientsService,
    public usersService: UsersService,
    public exportService: ExportService,
    public languageService: LanguageService,
  ) {
    this.maxDate.setDate(this.maxDate.getDate() + 7);
    this.bsInlineRangeValue = [this.bsInlineValue, this.maxDate];
  }

  async ngOnInit() {
  }

  onDateChange(date: any){
    console.log(date)
    this.bsInlineRangeValue = date;
  }

  async downloadShipmentExport(){
    console.log(`downloading shipment export from ${this.bsInlineRangeValue[0]} to ${this.bsInlineRangeValue[1]}`);
    const data = {
      from_date: this.bsInlineRangeValue[0].toISOString().split('T')[0],
      to_date: this.bsInlineRangeValue[1].toISOString().split('T')[0],
    }

    try {
      const response = await lastValueFrom(this.exportService.exportShipments(data));
      const blob = new Blob([response.body], { 'type': 'application/xlsx' });
      downloadFile(blob, `Sendungsexport von ${data.from_date} bis ${data.to_date}.xlsx`);

    } catch (error) {
      console.error(error)
    }
  }

  downloadClientExport(){
    console.log(`downloading clients`)
  }
}
