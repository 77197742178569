<h1 mat-dialog-title>
  <h3 *ngIf="!data.uuid">{{'common.add_vehicle' | translate}}</h3>
  <h3 *ngIf="!!data.uuid">{{'common.edit_vehicle' | translate}}</h3>
</h1>
<div mat-dialog-content>
  <div class="mt-2">

    <form [formGroup]="form">

      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>{{'common.truck_number' | translate}}</mat-label>
        <input matInput formControlName="number">
      </mat-form-field>

      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>{{'common.model' | translate}}</mat-label>
        <input matInput formControlName="model">
      </mat-form-field>

      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>{{'carrier_dashboard.user-editor-payload' | translate}} kg</mat-label>
        <input matInput formControlName="max_weight">
      </mat-form-field>

      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>{{'carrier_dashboard.number_of_slots' | translate}}</mat-label>
        <input matInput formControlName="max_ground_spaces">
      </mat-form-field>

      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>{{'carrier_dashboard.user-editor-description' | translate}}</mat-label>
        <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="10" formControlName="description"></textarea>
      </mat-form-field>

    </form>

  </div>

</div>
<div class="mb-2" mat-dialog-actions style="display: flex; justify-content: space-evenly">
  <button class="btn-primary light primary-hover-animation" mat-dialog-close="">{{'common.close' | translate}}</button>
  <button class="btn-primary green primary-hover-animation" (click)="save()" cdkFocusInitial>{{'common.save' | translate}}</button>
</div>
