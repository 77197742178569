import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { AuthService } from 'projects/webapp/app/services/auth.service';
import { IDetailedUser, UsersService } from 'projects/webapp/app/services/users.service';
import { PanelType } from 'projects/webapp/app/interfaces/panel-types.interface';
import { MatDialog } from '@angular/material/dialog';
import { ChangeLanguageModalComponent } from 'projects/webapp/app/components/change-language-modal/change-language-modal.component';
import { deepCopy } from 'projects/webapp/app/utils';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
  @Output() menuToggled = new EventEmitter<boolean>();

  searchTerm = '';

  userName: string = '';
  // get this from the back
  is_active: Boolean = true;
  // get messages
  notifications: number = 0;
  panelType: PanelType = 'carrier'
  canNavigateToOtherPanel = false;

  myUser: IDetailedUser | null = null;

  constructor(
    public authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    public usersService: UsersService,
    private dialog: MatDialog,
  ) {
    this.userName = this.authService.getUsername() || ''
  }

  ngOnInit(): void {
    this.usersService.ownUser$.asObservable().subscribe(val => {
      this.myUser = val;
    });
    console.log('hello user', this.myUser)
    this.checkNavigateToOtherPanel();
    this.route.url.subscribe(() => {
      this.checkNavigateToOtherPanel();
    })
  }

  checkNavigateToOtherPanel() {
    if (window.location.href.includes('/main/shipper')) {
      this.panelType = 'shipper'
    }
    if (window.location.href.includes('/main/carrier')) {
      this.panelType = 'carrier'
    }
    if (window.location.href.includes('/main/driver')) {
      this.panelType = 'driver'
    }
  }

  search() {
    const thisSearchTerm = deepCopy(this.searchTerm);
    this.searchTerm = '';
    this.router.navigate(
      ['/main/carrier/search'],
      {
        queryParams: {
          search: thisSearchTerm
        }, replaceUrl: true
      }
    )
  }

  navigateToProfile(): void {
    this.router.navigate([`/main/${this.panelType}/profile`])
  }

  navigateToClientOverviewManagement(): void {
    console.log('client management');
    this.router.navigate(['/main/carrier/clients'])
  }

  navigateToProjectManagement(): void {
    console.log('project management');
    this.router.navigate(['/main/carrier/all-projects'])
  }

  navigateToUserActivity(): void {
    console.log('user activity');
    this.router.navigate(['/main/carrier/activity'])
  }

  navigateToShipper(){
    this.router.navigate(['/main/shipper/dashboard'])
  }

  navigateToDriver() {
    this.router.navigate(['/main/driver/dashboard'])
  }

  navigateToCarrier() {
    this.router.navigate(['/main/carrier/dashboard'])
  }

  navigateToAdmin() {
    this.router.navigate(['/main/admin/dashboard'])
  }

  navigateToRoot() {
    if (window.location.href.includes('/main/shipper')) {
      return this.navigateToShipper()
    }
    if (window.location.href.includes('/main/driver')) {
      return this.navigateToDriver()
    }
    if (window.location.href.includes('/main/admin')) {
      return this.navigateToAdmin();
    }
    return this.navigateToCarrier()
  }

  navigateToAbout() {
    this.router.navigate([`/main/${this.panelType}/about`])
  }

  openLanguageSelector() {
    this.dialog.open(ChangeLanguageModalComponent, {
      width: '90vw',
    });
  }

  navigateToHelp() {
    window.open(environment.uiDomain + '/help', "_blank");
  }

  navigateToChat() {
    window.open('https://tawk.to/chat/6490042494cf5d49dc5e8910/1h3990bod', "_blank");
  }

  logout(): void {
    console.log('Logged out');
    this.authService.logout();
    this.router.navigate(['/'])
  }
}
