<div class="container">
    <mat-card class="mt-3">
        <mat-card-content *ngIf="shipment">
            <div class="d-flex flex-column flex-md-row justify-content-between" >
                <h2>{{'common.shipment_details' | translate}}: {{shipment.number | shipmentNumberPipe}}</h2>
                <div class="d-flex gap-2">
                    <a class="btn-primary red primary-hover-animation d-flex gap-2" [routerLink]="[]" (click)="cancelShipment()"
                        *ngIf="shipment.status != shipmentStatus.CANCELLED">
                        {{'common.cancel' | translate}}
                        <mat-icon>cancel</mat-icon>
                    </a>
                    <a class="btn-primary green primary-hover-animation d-flex gap-2" routerLink="/public/shipment-tracking/{{shipment.tracking_uuid}}"
                        target="_blank">
                        {{'common.track_shipment' | translate}}
                        <mat-icon>my_location</mat-icon>    
                    </a>
                    <a mat-icon-button color="primary" [routerLink]="" (click)="share()" *ngIf="hasShare">
                        <mat-icon>share</mat-icon>
                    </a>
                </div>
            </div>

            <hr>

            <div class="my-3 d-flex gap-4 w-100">
                <button class="btn-primary green primary-hover-animation d-flex gap-2" (click)="downloadDeliverySlip()">{{'common.delivery_slip' | translate}} <mat-icon>file_copy</mat-icon></button>
                <button class="btn-primary green primary-hover-animation d-flex gap-2" (click)="downloadLabel()">{{'common.label' | translate}} <mat-icon>file_copy</mat-icon></button>
            </div>

            <hr>

            <ul class="co-simple-list">

                <!-- TODO: add here source (api, public wizard, private wizard, market place etc.) -->
                <li *ngIf="shipment.custom_number"><strong>{{'common.custom_number' | translate}}:</strong> {{shipment.custom_number}}</li>
                <li *ngIf="shipment.assigned_tour_object"><strong>{{'common.tour' | translate}}:</strong> <a routerLink="/main/carrier/tours/{{shipment.assigned_tour_object.uuid}}">
                {{shipment.assigned_tour_object.id}}
                </a>
                </li>
                <li *ngIf="shipment.client_object"><strong>Client: </strong> <a routerLink="/main/carrier/clients/{{shipment.client_object.uuid}}/details">{{shipment.client_object.company}}, {{shipment.client_object.first_name}} {{shipment.client_object.last_name}}</a></li>
                <li><strong>{{'common.created' | translate}}:</strong> {{shipment.created_at | humanDatePipe}} </li>
                <li><strong>{{'common.last_updated' | translate}}:</strong> {{shipment.updated_at | humanDatePipe}}</li>
                <li><strong>{{'common.current_location' | translate}}:</strong> {{shipment.current_loc | shipmentCurrentLocationStatusPipe}}</li>
                <!-- <li>{{'common.task' | translate}}: {{shipment.current_loc | shipmentCurrentLocationStatusPipe}}</li> -->
                <li *ngIf="shipment.assigned_driver_object">
                    <span style="display: flex; align-items: center; ">
                        <span>
                            <strong>{{'common.current_driver' | translate}}:&nbsp;</strong>
                        </span>
                        <a routerLink="/main/carrier/settings/users/{{shipment.assigned_driver_object.uuid}}/details">
                            {{shipment.assigned_driver_object.first_name}}
                            {{shipment.assigned_driver_object.last_name}}
                        </a>

                        <mat-icon class="mx-2" style="font-size: 20px;"
                            (click)="editCurrentDriver()">edit</mat-icon>
                    </span>
                </li>
                <li *ngIf="shipment.assigned_driver_object">
                    <strong>{{'common.task' | translate}}: </strong> {{shipment.task | shipmentTaskStatusPipe }}
                </li>
                <li>
                    <span style="display: flex; align-items: center; ">
                        <strong class="me-2">{{'common.status' | translate}}: </strong> {{shipment.status | shipmentStatusPipe}}
                        <a mat-icon-button color="primary" class="mx-2" style="font-size: 20px;" (click)="editShipmentStatus()"><mat-icon>edit</mat-icon></a>
                    </span>

                </li>

            </ul>
            <div class="alert alert-warning mt-2 mb-2 d-flex justify-content-between" *ngIf="!shipment.client_object">
                <span>
                    {{'common.not_assigned_to_any_customer' | translate}}
                </span>
                <button (click)="changeClient()" class="btn-primary green primary-hover-animation d-flex gap-2">{{'common.assign' | translate}}</button>
            </div>

        </mat-card-content>
    </mat-card>

    <app-shipment-address [shipment]="shipment" [type]="'carrier'"></app-shipment-address>

    <mat-card class="mt-3">
        <mat-card-content *ngIf="shipment">
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <h5>{{'common.cargoes' | translate}}</h5>
                <a mat-icon-button color="primary"
                    routerLink="/main/carrier/shipments/{{shipment.uuid}}/edit-cargo-items">
                    <mat-icon>edit</mat-icon>
                </a>
            </div>

            <ul class="co-simple-list">
                <li><strong>{{'common.insured' | translate}}:</strong> {{shipment.insurance | yesNoPipe}}</li>
                <li><strong>{{'common.goods_value' | translate}}:</strong> {{ownOrganization?.default_currency}} {{shipment.value}} </li>
                <li><strong>{{'common.total_weight' | translate}}:</strong> {{shipment.total_weight}}kg </li>
            </ul>
            <hr>

            <table *ngIf="!!shipment.packets && shipment.packets.length > 0" style="width: 100%"
                class="table table-striped">
                <thead>
                    <td>{{'common.name' | translate}}</td>
                    <td>{{'common.type' | translate}}</td>
                    <td>{{'common.quantity' | translate}}</td>
                    <td>{{'common.mass' | translate}} (cm)</td>
                    <td>{{'common.total_weight' | translate}} (kg)</td>
                </thead>

                <tbody>
                    <tr *ngFor="let packet of shipment.packets">
                        <td>{{packet.name}}</td>
                        <td>{{packet.type | cargoItemName}}</td>
                        <td>{{packet.count}}</td>
                        <td>L{{packet.length}}xB{{packet.width}}xH{{packet.height}}</td>
                        <td>{{packet.weight}}</td>
                    </tr>
                </tbody>
            </table>

        </mat-card-content>
    </mat-card>

    <mat-card class="mt-3">
        <mat-card-content *ngIf="shipment">
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <h5>{{'common.empty_containers' | translate}}</h5>
                <a mat-icon-button color="primary" routerLink="/main/carrier/shipments/{{shipment.uuid}}/edit-empties">
                    <mat-icon>edit</mat-icon>
                </a>
            </div>

            <div class="alert alert-info" role="alert" *ngIf="shipment.empties">
                {{'common.empty_container_exchange_ordered' | translate}}
            </div>

            <div class="alert alert-info" role="alert" *ngIf="!shipment.empties">
                <strong>{{'common.none' | translate}}</strong> {{'common.empty_container_exchange_ordered' | translate}}
            </div>

            <table style="width: 100%" class="table table-striped" *ngIf="shipment.empties">
                <thead>
                    <!-- <td></td> -->
                    <td>{{'common.pallets' | translate}}</td>
                    <!-- <td>{{'common.frames' | translate}}</td>
                    <td>{{'common.lids' | translate}}</td> -->
                </thead>

                <tbody>
                    <tr>
                        <!-- <td>Beauftragt</td> -->
                        <td>{{shipment.empties_eur_palets}}</td>
                        <!-- <td>{{shipment.empties_sbb_frames}}</td>
                        <td>{{shipment.empties_sbb_covers}}</td> -->
                    </tr>
                </tbody>
            </table>
        </mat-card-content>
    </mat-card>

    <app-shipment-pricing [shipmentUUID]="shipmentUUID" [panelType]="'carrier'" ></app-shipment-pricing>

    <app-shipment-events *ngIf="shipment && shipment.events" [events]="shipment.events"> </app-shipment-events>

    <app-shipment-images [shipmentUUID]="shipmentUUID"></app-shipment-images>

    <app-shipment-meta-data *ngIf="shipment && shipment.meta_data" [meta_data]="shipment.meta_data"></app-shipment-meta-data>

    <mat-card class="mt-3">
        <mat-card-content>
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <h5>{{'common.internal_comments' | translate}}</h5>
                <div>
                    <button mat-icon-button color="primary" (click)="refreshPrivateComments()">
                        <mat-icon>refresh</mat-icon>
                    </button>
                    <button mat-icon-button color="primary" (click)="onAddPrivateComment()">
                        <mat-icon>add_circle</mat-icon>
                    </button>
                </div>
            </div>

            <ngx-spinner name="privateShipmentComments" [fullScreen]="false"></ngx-spinner>

            <div class="alert alert-info" *ngIf="!privateShipmentComments || privateShipmentComments.length == 0">
                {{'common.no_comments_yet' | translate}}</div>

            <table style="width: 100%" class="table table-striped"
                *ngIf="privateShipmentComments && privateShipmentComments.length > 0">
                <thead>
                    <td>{{'common.who' | translate}}</td>
                    <td>{{'common.when' | translate}}</td>
                    <td>{{'common.what' | translate}}</td>
                </thead>

                <tbody>

                    <tr *ngFor="let comment of privateShipmentComments">
                        <td>
                            <span *ngIf="comment.author_object">
                                {{comment.author_object.first_name}} {{comment.author_object.last_name}}
                            </span>
                            <span *ngIf="!comment.author_object">Unbekannt</span>
                        </td>
                        <td>{{ comment.updated_at | humanDatePipe }}</td>
                        <td>{{ comment.content }}</td>
                        <td>
                            <button mat-icon-button color="primary" (click)="onUpdateComment(comment)">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button mat-icon-button color="primary" (click)="onDeleteComment(comment)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                    </tr>

                </tbody>
            </table>
        </mat-card-content>
    </mat-card>

    <mat-card class="mt-3">
        <mat-card-content>
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <h5>{{'common.public_comments' | translate}}</h5>
                <div>
                    <button mat-icon-button color="primary" (click)="refreshPublicComments()">
                        <mat-icon>refresh</mat-icon>
                    </button>
                    <button mat-icon-button color="primary" (click)="onAddPublicComment()">
                        <mat-icon>add_circle</mat-icon>
                    </button>
                </div>
            </div>

            <ngx-spinner name="publicShipmentComments" [fullScreen]="false"></ngx-spinner>

            <div class="alert alert-info" *ngIf="!publicShipmentComments || publicShipmentComments.length == 0">
                {{'common.no_comments_yet' | translate}}
            </div>

            <table style="width: 100%" class="table table-striped"
                *ngIf="publicShipmentComments && publicShipmentComments.length > 0">
                <thead>
                    <td>{{'common.who' | translate}}</td>
                    <td>{{'common.when' | translate}}</td>
                    <td>{{'common.what' | translate}}</td>
                </thead>

                <tbody>

                    <tr *ngFor="let comment of publicShipmentComments">
                        <td>
                            <span *ngIf="comment.author_object">
                                {{comment.author_object.first_name}} {{comment.author_object.last_name}}
                            </span>
                            <span *ngIf="!comment.author_object">Unbekannt</span>
                        </td>
                        <td>{{ comment.updated_at | humanDatePipe }}</td>
                        <td>{{ comment.content }}</td>
                        <td>
                            <button mat-icon-button color="primary" (click)="onUpdateComment(comment)">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button mat-icon-button color="primary" (click)="onDeleteComment(comment)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                    </tr>

                </tbody>
            </table>
        </mat-card-content>
    </mat-card>

    <!-- <mat-card class="mt-3">
        <mat-card-content *ngIf="shipment">
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <h5>Label</h5>
            </div>
            <div class="qrcodeImage">
                <qrcode [qrdata]="environment.uiDomain + '/public/shipment-tracking/' + shipment.tracking_uuid"
                    [width]="250" [errorCorrectionLevel]="'M'"></qrcode>
            </div>
        </mat-card-content>
    </mat-card> -->

</div>
