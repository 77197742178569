<mat-form-field appearance="outline" style="width: 100%" *ngIf="!!possibleClients && possibleClients.length > 0">
  <mat-label translate>carrier_dashboard.clients.main_title</mat-label>
  <!-- <pre>{{'common.client' | translate}} {{selectedClientUUID}}</pre> -->
  <input type="text" matInput [(ngModel)]="selectedClient" [matAutocomplete]="auto" [formControl]="clientFormControl">
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="getTitle.bind(this)"
    (optionSelected)='clientSelected($event.option.value)'>
    <mat-option *ngFor="let option of filteredClients" [value]="option">
      {{getClientName(option)}}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="clientFormControl.hasError('required')" translate>carrier_dashboard.field_required</mat-error>
</mat-form-field>

<div class="alert alert-info" role="alert" style="width: 100%" *ngIf="!possibleClients || possibleClients.length == 0">
  {{'carrier_dashboard.no_customers_yet' | translate}} <a routerLink="/main/carrier/clients" translate>carrier_dashboard.customer_management</a> {{'common.create' | translate}}
</div>
