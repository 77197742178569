<div class="co-card">
  <div class="p-4" *ngIf="shipmentForm">
    <h5 class="card-title pb-4" *ngIf="type=='pickup'" translate>common.pick-up-location</h5>
    <h5 class="card-title pb-4" *ngIf="type=='delivery'" translate>common.delivery_location</h5>

    <form [formGroup]="shipmentForm">

      <div class="mb-3">
        <div class="form-floating">
          <input class="form-control" type="text" [id]="type + '_company'" [formControlName]="type + '_company'">
          <label [for]="type + '_company'" class="form-label" translate>common.firma</label>
        </div>
      </div>

      <div class="mb-3">
        <div class="form-floating">
          <input class="form-control" type="text" [id]="type + '_name'" [formControlName]="type + '_name'">
          <label [for]="type + '_name'" class="form-label" translate>common.first_name_last_name</label>
        </div>
      </div>

      <div class="mb-3">
        <div class="form-floating">
          <input class="form-control" type="text" [id]="type + '_address'" [formControlName]="type + '_address'"
            [ngClass]="{'is-valid': shipmentForm.get(type + '_address').touched && shipmentForm.get(type + '_address').valid, 'is-invalid': shipmentForm.get(type + '_address').touched && shipmentForm.get(type + '_address').invalid}">
          <label [for]="type + '_address'" class="form-label" translate>common.address</label>
          <div class="invalid-feedback"
            *ngIf="shipmentForm.get(type + '_address').invalid && shipmentForm.get(type + '_address').touched" translate>
            carrier_dashboard.field_obligatory
          </div>
        </div>
      </div>

      <div class="mb-3">
        <div class="form-floating">
          <input class="form-control" type="text" [id]="type + '_zip'" [formControlName]="type + '_zip'"
            [ngClass]="{'is-valid': shipmentForm.get(type + '_zip').touched && shipmentForm.get(type + '_zip').valid, 'is-invalid': shipmentForm.get(type + '_zip').touched && shipmentForm.get(type + '_zip').invalid}">
          <label [for]="type + '_zip'" class="form-label" translate>common.postcode</label>
          <div class="invalid-feedback"
            *ngIf="shipmentForm.get(type + '_zip').invalid && shipmentForm.get(type + '_zip').touched" translate>
            carrier_dashboard.field_obligatory
          </div>
        </div>
      </div>


      <div class="mb-3">
        <div class="form-floating">
          <input class="form-control" type="text" [id]="type + '_location'" [formControlName]="type + '_location'"
            [ngClass]="{'is-valid': shipmentForm.get(type + '_location').touched && shipmentForm.get(type + '_location').valid, 'is-invalid': shipmentForm.get(type + '_location').touched && shipmentForm.get(type + '_location').invalid}">
          <label [for]="type + '_location'" class="form-label" translate>common.locality</label>
          <div class="invalid-feedback"
            *ngIf="shipmentForm.get(type + '_location').invalid && shipmentForm.get(type + '_location').touched" translate>
            carrier_dashboard.field_obligatory
          </div>
        </div>
      </div>

      <hr>

      <div class="mb-3">
        <input class="form-control" type="date" [placeholder]="('common.pickup_date' | translate)" [formControlName]="type + '_date'"
          [ngClass]="{'is-valid': shipmentForm.get(type + '_date').touched && shipmentForm.get(type + '_date').valid, 'is-invalid': shipmentForm.get(type + '_date').touched && shipmentForm.get(type + '_date').invalid}" />
        <div class="invalid-feedback"
          *ngIf="shipmentForm.get(type + '_date').invalid && shipmentForm.get(type + '_date').touched" translate>
          carrier_dashboard.please_enter_date
        </div>
      </div>

      <div class="mb-3">
        <select class="form-select">
          <option value="1" selected translate>carrier_dashboard.during_the_day</option>
          <option value="2" translate>carrier_dashboard.until_midday</option>
          <option value="3" translate>carrier_dashboard.in_the_afternoon</option>
        </select>
      </div>

      <div class="form-check">
        <input class="form-check-input" type="checkbox" id="notification_{{type}}"
          [formControlName]="type + '_notification'">
        <label class="form-check-label" for="notification_{{type}}" translate>
          common.notification
        </label>
      </div>

      <div class="form-check">
        <input class="form-check-input" type="checkbox" id="date_flexible_{{type}}"
          [formControlName]="type + '_date_flexible'">
        <label class="form-check-label" for="date_flexible_{{type}}">
          <span *ngIf="type=='pickup'" translate>carrier_dashboard.flexible_pickup</span>
          <span *ngIf="type=='delivery'" translate>carrier_dashboard.flexible_delivery</span>
        </label>
      </div>

      <div class="mb-3" *ngIf="shipmentForm.value[type + '_notification']">
        <div class="form-floating">
          <input class="form-control" type="tel" id="call_notification_{{type}}"
            [formControlName]="type + '_notification_call'">
          <label [for]="type + '_notification_call'" class="form-label" translate>common.phone</label>
        </div>
      </div>
    </form>
  </div>
</div>
