<div class="container my-4 position-relative">
  <div class="d-flex justify-content-center">
      <h5 class="fs-1 text-center">{{'driver_app.dashboard.main_title' | translate}} </h5>
      <div class="spinner-grow" role="status" *ngIf="isLoading">
      </div>
      <a style="top: -1rem; right: 0.5rem;" class="d-flex fs-1 fw-bolder position-absolute" mat-icon-button color="primary" [routerLink]="[]" (click)="refresh()">
        <mat-icon class="fs-1 fw-bolder">refresh</mat-icon>
      </a>
  </div>
</div>

<div class="container custom-dashboard">

  <div class="row">

    <div class="col-md-12 col-lg-4 " *ngIf="(usersService.ownUser$ | async)?.preference_show_shipments_to_pickup">
      <mat-card class="my-2 text-center co-pointer co-dashboard-card rounded-3" (click)="navigateToPickup()">
        <mat-card-content>
          {{'driver_app.dashboard.pick_up' | translate}}
          <br>
          <span style="font-size: 4rem"
            *ngIf="(organizationsService.ownOrg$ | async)?.admin_permission_software_license else noSubscription">{{dashboardNumbers?.shipments_to_pickup}}</span>
          <br>
          <!-- <small>für mich</small> -->
        </mat-card-content>
      </mat-card>
    </div>


    <div class="col-md-12 col-lg-4 " *ngIf="(usersService.ownUser$ | async)?.preference_show_shipments_in_vehicle">
      <mat-card class="my-2 text-center co-pointer co-dashboard-card rounded-3" (click)="navigateToShipmentsInVehicle()">
        <mat-card-content>
          {{'carrier_dashboard.in_the_vehicle' | translate}}
          <br>
          <span style="font-size: 4rem"
            *ngIf="(organizationsService.ownOrg$ | async)?.admin_permission_software_license else noSubscription">{{dashboardNumbers?.shipments_in_vehicle}}</span>
          <br>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-md-12 col-lg-4 " *ngIf="(usersService.ownUser$ | async)?.preference_show_shipments_to_deliver">
      <mat-card class="my-2 text-center co-pointer co-dashboard-card rounded-3" (click)="navigateToDelivery()">
        <mat-card-content>
          {{'driver_app.dashboard.delivery' | translate}}
          <br>
          <span style="font-size: 4rem"
            *ngIf="(organizationsService.ownOrg$ | async)?.admin_permission_software_license else noSubscription">{{dashboardNumbers?.shipments_to_deliver}}</span>
          <br>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

</div>

<ng-template #noSubscription>
  <img src="assets/Lock-Cargoflow.png" loading="lazy" alt="" width="50px" class="image">
</ng-template>
