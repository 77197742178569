<h1 mat-dialog-title>
  <span *ngIf="!data.uuid">{{'common.add_hub' | translate}}</span>
  <span *ngIf="!!data.uuid">{{'common.edit_hub' | translate}}</span>
</h1>
<div mat-dialog-content>

  <mat-form-field appearance="outline" style="width: 100%">
    <mat-label translate>common.name_hub</mat-label>
    <input matInput [(ngModel)]="data.name" required>
  </mat-form-field>

  <mat-form-field appearance="outline" style="width: 100%">
    <mat-label translate>common.address</mat-label>
    <input matInput [(ngModel)]="data.address" required>
  </mat-form-field>

  <mat-form-field appearance="outline" style="width: 100%">
    <mat-label translate>common.locality</mat-label>
    <input matInput [(ngModel)]="data.location">
  </mat-form-field>

  <!-- <pre>{{data | json}}</pre> -->

</div>
<div class="mb-2" mat-dialog-actions style="display: flex; justify-content: space-evenly">
  <button class="btn-primary light primary-hover-animation" mat-dialog-close="">{{'common.close' | translate}}</button>
  <button class="btn-primary green primary-hover-animation" [mat-dialog-close]="data" cdkFocusInitial>{{'common.save' | translate}}</button>
</div>