<ngx-spinner type="ball-scale-multiple">
  <button class="mt-3 btn-primary warning primary-hover-animation" (click)="cancelLoading()">{{'common.cancel' |
    translate}}</button>
</ngx-spinner>

<div class="main-container">
  <app-header (menuToggled)="toggle($event)"></app-header>

  <!-- <mat-drawer-container [ngClass]="panelType == 'driver'? 'd-none d-sm-block': ''"> -->
  <mat-drawer-container>
    <mat-drawer mode="side" #leftSideNav [opened]="opened">
      <div style="display: flex; flex-direction: column; justify-content: space-between; height: 100%;">
        <app-menu-item [menu]="menu" (elementClicked)="menuItemClicked($event)"></app-menu-item>
        <div style="display: flex;flex-direction: column; align-items: center;"
          [ngClass]="panelType == 'driver'? 'padding-bottom': ''">
          <small style="align-self: center;" class="pb-3">{{(organizationsService.ownOrg$ | async)?.name}}</small>
          <small style="align-self: center;" class="pb-3">v{{versions.date}}</small>
        </div>
      </div>
    </mat-drawer>


    <mat-drawer-content [class.margin-left]="opened">
      <router-outlet></router-outlet>
      <!-- <pre>{{myUser | json}}</pre> -->
      <!-- <br>
            <br>
            <br>
            <br>
            <br>
            <br> -->
    </mat-drawer-content>
  </mat-drawer-container>
  <mat-toolbar class="toolbarNav" *ngIf="panelType == 'driver'">
    <div class="toolbarNav__container">
      <button mat-flat-button [routerLink]="this.menu[1].link" routerLinkActive="active-link">
        <!-- <mat-icon class="material-icons color_blue">skip_previous</mat-icon> -->
        <img src="assets/driver/shipping.svg" style="height: 20px;" loading="lazy" alt="">
        <span>{{this.menu[1].title}}</span>
      </button>
      <button mat-flat-button [routerLink]="this.menu[2].link" routerLinkActive="active-link">
        <img src="assets/driver/box.svg" style="height: 20px;" loading="lazy" alt="">
        <span>{{this.menu[2].title}}</span>
      </button>
      <!-- implement middle functionality -->
      <button mat-flat-button [routerLink]="this.menu[0].link" routerLinkActive="active-link" class="middle-element">
        <div>
          <!-- <img src="assets/driver/scan.svg" style="height: 20px;" loading="lazy" alt=""> -->
          <mat-icon style="color: white;"> dashboard </mat-icon>
        </div>
      </button>
      <button mat-flat-button [routerLink]="this.menu[3].link" routerLinkActive="active-link">
        <img src="assets/driver/truck.svg" style="height: 20px;" loading="lazy" alt="">
        <span>{{this.menu[3].title}}</span>
      </button>
      <!-- implement map button -->
      <button mat-flat-button [routerLink]="this.menu[4].link" routerLinkActive="active-link">
        <img src="assets/driver/profile.svg" style="height: 20px;" loading="lazy" alt="">
        <span>{{this.menu[4].title}}</span>
      </button>
    </div>
    <hr>
  </mat-toolbar>
</div>