<div class="container-fluid">

    <mat-card class="mt-3">
        <mat-card-content>
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <h1>{{'carrier_dashboard.activities.main_title' | translate}}</h1>
                <button mat-icon-button color="primary" (click)="refresh()">
                    <mat-icon>refresh</mat-icon>
                </button>
            </div>

            <table class="table table-custom" style="width: 100%"
                *ngIf="!!activityEvents && activityEvents.length > 0">
                <thead>
                    <td>{{'common.who' | translate}}</td>
                    <td>{{'common.what' | translate}}</td>
                    <td>{{'common.when' | translate}}</td>
                    <!-- <td></td> -->
                </thead>

                <tbody>
                    <tr *ngFor="let event of activityEvents">
                        <td>
                            {{event.author_object.first_name }} {{event.author_object.last_name }}
                            ({{ event.author_object.organization_name }})
                        </td>
                        <!-- TODO: Display the correct events -->
                        <td>{{event.type | enumIntToDescription: EventType }}</td>
                        <td>
                            {{ event.updated_at_human }}
                        </td>
                        <!-- <td>
                            <button mat-icon-button (click)="navigateToDetails(event)" *ngIf="event.entity_uuid">
                                <mat-icon>arrow_right</mat-icon>
                            </button>
                        </td> -->
                    </tr>
                </tbody>
            </table>

            <div *ngIf="!activityEvents || activityEvents?.length === 0" class="alert alert-info w-100">
                {{'carrier_dashboard.activities.no-activities' | translate}}
            </div>
            <!-- <pre>{{activityEvents | json}}</pre> -->
        </mat-card-content>
    </mat-card>
</div>
