import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { IAuctionPrice } from 'projects/webapp/app/components/license-modal/license-modal.component';

import { TenderPricingModalComponent } from 'projects/webapp/app/components/tender-pricing-modal/tender-pricing-modal.component';
import { IOrganization } from 'projects/webapp/app/interfaces/organization.interface';
import { ICarrierShipment } from 'projects/webapp/app/interfaces/shipment.interface';
import { AuthService } from 'projects/webapp/app/services/auth.service';
import { CarrierShipmentsService } from 'projects/webapp/app/services/carrier-shipments.service';
import { ClientsService } from 'projects/webapp/app/services/clients.service';
import { OrganizationsService } from 'projects/webapp/app/services/organizations.service';
import { IShipment } from 'projects/webapp/app/services/shipments.service';
import { UsersService } from 'projects/webapp/app/services/users.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-pipeline-release',
  templateUrl: './pipeline-release.component.html',
  styleUrls: ['./pipeline-release.component.scss']
})
export class PipelineReleaseComponent implements OnInit {

  shipmentsForApproval: ICarrierShipment[] = []
  isLoading = false;

  ownOrganization: IOrganization | null = null;

  constructor(
    public authService: AuthService,
    public clientService: ClientsService,
    public carrierShipmentsService: CarrierShipmentsService,
    public usersService: UsersService,
    public dialog: MatDialog,
    private title: Title,
    private spinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
    private translateService: TranslateService,
    private organizationService: OrganizationsService,
  ) {
    this.translateService.get('common.pipeline').subscribe(val => {
      this.title.setTitle(`Cargoflow: ${val}`);
    });
  }

  async ngOnInit() {
    this.ownOrganization = await this.organizationService.getOwnOrganization().toPromise() as any;
    this.refresh()
  }

  async refresh() {
    try {
      this.spinnerService.show()
      this.isLoading = true;
      this.shipmentsForApproval = await lastValueFrom(this.carrierShipmentsService.getShipmentsForApproval())
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
      this.spinnerService.hide()
    }
  }

  async acceptShipment(shipment: IShipment) {
    if (shipment && shipment.uuid) {
      const successMessage = await lastValueFrom(this.translateService.get('toastr.shipment_relesed_for_dispatch'));

      await lastValueFrom(this.carrierShipmentsService.acceptShipment(shipment.uuid));
      this.toastrService.success(successMessage)
      this.refresh();
    }
  }

  async acceptAll() {
    const successMessage = await lastValueFrom(this.translateService.get('toastr.shipment_relesed_for_dispatch'));
    const errorMessage = await lastValueFrom(this.translateService.get('toastr.something_went_wrong'));

    try {
      this.spinnerService.show()
      for (let index = 0; index < this.shipmentsForApproval.length; index++) {
        const shipment = this.shipmentsForApproval[index];
        if (shipment && shipment.uuid) {
          await lastValueFrom(this.carrierShipmentsService.acceptShipment(shipment.uuid));
        }
      }
      this.toastrService.success(successMessage)
    } catch (error) {
      console.error(error)
      this.toastrService.error(errorMessage)
    } finally {
      this.spinnerService.hide()
      this.refresh();
    }

  }

  sellShipment(shipment: IShipment) {
    // alert('Der Cargoflow Team wird Ihr Account bald freischalten, so dass Sie Sendungen verkaufen können.')
    // return;

    this.dialog.open(TenderPricingModalComponent, { data: shipment }).afterClosed().subscribe(async (val: IAuctionPrice) => {
      if (!!val) {
        const data = {
          carrier_pricing: val
        }
        if (shipment.uuid) {
          await lastValueFrom(this.carrierShipmentsService.sellShipment(shipment.uuid, data));
          this.refresh()
        }
      }
    });
  }
}
