<div class="container my-4 px-4">
  <div class="d-flex flex-column">
    <h5 class="order-1" translate>title_dispatcher_dashboard</h5>
    <div style="display: flex; justify-content: end; align-items: center; width: 100%;">
      <div class="spinner-grow" role="status" *ngIf="isLoading">
      </div>

      <!-- <input [(ngModel)]="searchTerm"
        style="border-radius: 30px; height: 30px; border: solid 1px green; background-color: lightgray; color: darkslategray;"
        placeholder="..." (keyup.enter)="search()"> -->

      <!-- <a mat-icon-button color="primary" [routerLink]="[]" (click)="search()">
        <mat-icon>search</mat-icon>
      </a> -->
      <a mat-icon-button color="primary" [routerLink]="[]" (click)="refresh()">
        <mat-icon>refresh</mat-icon>
      </a>
    </div>
  </div>
</div>

<div class="container custom-dashboard">
  <div class="mx-4">
    <!-- <hr style="color: grey;"> -->
  </div>
  <div class="row">

    <div  style="padding: 0 2rem;" class="col-md-12 col-lg-4 "
      *ngIf="(usersService.ownUser$ | async)?.permission_market_place && (organizationsService.ownOrg$ | async)?.have_market_place && (usersService.ownUser$ | async)?.preference_show_shipments_to_pickup">
      <mat-card class="mb-4 mt-4 text-center co-pointer co-dashboard-card primary-hover-animation" (click)="navigateToMarketPlace()">
        <mat-card-content>

          {{"carrier_dashboard.market_place" | translate}}
          <br>
          <span style="font-size: 4rem">{{dashboardNumbers?.shipments_in_market_place}}</span>
          <br>
          <small>{{"carrier_dashboard.shipments_in_market_place" | translate}}</small>
        </mat-card-content>
      </mat-card>
    </div>

    <div  style="padding: 0 2rem;" class="col-md-12 col-lg-4 " *ngIf="(usersService.ownUser$ | async)?.permission_pipeline">
      <mat-card class="mb-4 mt-4 text-center co-pointer co-dashboard-card primary-hover-animation" (click)="navigateToPipeline()">
        <mat-card-content>
          {{'carrier_dashboard.release_pipeline.tile_name' | translate}}
          <br>
          <span style="font-size: 4rem"
            *ngIf="(organizationsService.ownOrg$ | async)?.admin_permission_software_license else noSubscription">
            {{dashboardNumbers?.shipments_for_approval}}
          </span>
          <br>
          <small>{{ 'carrier_dashboard.tile.release_pipeline.help_text' | translate }}</small>
        </mat-card-content>
      </mat-card>
    </div>

    <div style="padding: 0 2rem;" class="col-md-12 col-lg-4 " *ngIf="(usersService.ownUser$ | async)?.permission_alerts_place">
      <mat-card class="mb-4 mt-4 text-center co-pointer co-dashboard-card primary-hover-animation" (click)="navigateToAlerts()">
        <mat-card-content>
          {{'carrier_dashboard.warning.tile_name' | translate}}
          <br>
          <span style="font-size: 4rem"
            *ngIf="(organizationsService.ownOrg$ | async)?.admin_permission_software_license else noSubscription">{{dashboardNumbers?.shipments_in_alert}}</span>
          <br>
          <small>{{'carrier_dashboard.client_shipment_with_warning' | translate}} </small>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <!-- <div class="container mt-4 mb-4 text-center d-flex justify-content-center" *ngIf="(usersService.ownUser$ | async)?.permission_new_shipments">
    <button class="btn-primary green primary-hover-animation" [routerLink]="['/main/carrier/wizard']">
      {{'carrier_dashboard.client_shipment' | translate}}
    </button>
  </div>

  <div class="container mt-4 mb-4 text-center d-flex justify-content-center" *ngIf="(usersService.ownUser$ | async)?.permission_new_shipments">
    <button class="btn-primary green primary-hover-animation" [routerLink]="['/main/shipper/wizard']">
      {{'carrier_dashboard.enter_consignment_as_sender' | translate}}
    </button>
  </div> -->
  <div class="container mt-4 mb-4 text-center d-flex justify-content-center" *ngIf="(usersService.ownUser$ | async)?.permission_new_shipments">
    <button class="btn-primary green primary-hover-animation" [routerLink]="['/main/carrier/wizard']">
      {{'common.record_shipment' | translate}}
    </button>

  </div>
    <div class="container mt-4 mb-4 text-center d-flex justify-content-center"
      >
      <button class="btn-primary green primary-hover-animation" [routerLink]="['/main/carrier/maps']">
        {{'common.maps' | translate}}
      </button>

    </div>
</div>

<ng-template #noSubscription>
  <img src="assets/Lock-Cargoflow.png" loading="lazy" alt="" width="50px" class="image">
</ng-template>
