<div class="page-container pb-4 mb-5">

  <div class="container mb-5">

    <mat-card>
      <mat-card-content>
        <div style="display: flex; flex-direction: row; justify-content: space-between;">
          <h1>{{'carrier_dashboard.profile.user_profile' | translate}}</h1>
        </div>
        <hr>

        <form [formGroup]="form" (ngSubmit)="submit()">
          <div class="d-flex justify-content-center pb-3">
            <app-avatar formControlName="avatardocument"></app-avatar>
          </div>
          <div class="row mt-4">

            <mat-form-field appearance="outline" class="col-6">
              <mat-label>{{'common.first_name' | translate}}</mat-label>
              <input matInput formControlName="first_name">
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-6">
              <mat-label>{{'common.last_name'| translate}}</mat-label>
              <input matInput formControlName="last_name">
            </mat-form-field>

          </div>
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>{{'common.mail' | translate}}</mat-label>
            <input matInput formControlName="email">
          </mat-form-field>

          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>{{'common.phone' | translate}}</mat-label>
            <input matInput formControlName="phone">
          </mat-form-field>

          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>{{'common.username' | translate}}</mat-label>
            <input matInput formControlName="username" readonly>
          </mat-form-field>

          <div class="d-flex justify-content-end">
            <button class="btn-primary green primary-hover-animation">{{'common.save' | translate}}</button>
          </div>

        </form>
      </mat-card-content>
    </mat-card>
    <hr>
    <mat-card class="mt-4">
      <mat-card-content>
        <h1>{{'carrier_dashboard.profile.my_preferences' | translate}}</h1>

        <form [formGroup]="form" (ngSubmit)="submit()">
          <div style="display: flex; flex-direction: column;">

            <mat-checkbox color="primary" *ngIf="(usersService.ownUser$ | async)?.permission_market_place"
              formControlName="preference_show_market_place">{{'carrier_dashboard.profile.show_marketplace' | translate
              }}</mat-checkbox>
            <mat-checkbox color="primary" *ngIf="(usersService.ownUser$ | async)?.permission_invoicing"
              formControlName="preference_show_facturation">{{'carrier_dashboard.profile.show_invoice' | translate
              }}</mat-checkbox>
            <mat-checkbox color="primary" *ngIf="(usersService.ownUser$ | async)?.permission_activity"
              formControlName="preference_show_activity">{{'carrier_dashboard.profile.show_activity' | translate
              }}</mat-checkbox>

            <!-- <hr> -->

            <mat-checkbox color="primary" formControlName="preference_show_shipments_to_pickup">
              {{'carrier_dashboard.profile.show_shipments_to_pickup' | translate }}
            </mat-checkbox>
            <mat-checkbox color="primary" formControlName="preference_show_shipments_in_vehicle">
              {{'carrier_dashboard.profile.show_shipments_in_truck' | translate }}
            </mat-checkbox>
            <mat-checkbox color="primary" formControlName="preference_show_shipments_to_deliver">
              {{'carrier_dashboard.profile.show_shipments_to_delivery' | translate}}
            </mat-checkbox>
          </div>

          <div class="d-flex justify-content-end">
            <button class="btn-primary green primary-hover-animation">{{'common.save' | translate}}</button>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
    <hr>
    <mat-card>
      <mat-card-content>
        
        <h1>{{'common.language' | translate}}</h1>
        <app-language-changer></app-language-changer>
      </mat-card-content>
    </mat-card>

    <mat-card class="mt-4" *ngIf="tokens.length > 0">
      <mat-card-content>
        <h1 translate>carrier_dashboard.my_tokens</h1>

        <table *ngIf="!!tokens && tokens.length > 0" style="width: 100%" class="table table-striped">
          <thead>
            <td translate>common.creation_date</td>
            <td translate>carrier_dashboard.device_ip</td>
            <td translate>carrier_dashboard.device_name</td>
            <!-- <td>User Agent</td> -->
            <td translate>common.active</td>
          </thead>

          <tbody>
            <tr *ngFor="let user of tokens">
              <td>{{user.created_at | date: 'yyyy-MM-dd HH:mm'}}</td>
              <td>{{user.client_ip}}</td>
              <td>{{user.device_name}}</td>
              <!-- <td>{{user.user_agent}}</td> -->
              <td>{{user.active | yesNoPipe}}</td>
            </tr>
          </tbody>
        </table>
      </mat-card-content>
    </mat-card>
    <!-- <pre>{{tokens | json}}</pre> -->

  </div>

</div>