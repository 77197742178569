<div class="container mt-4 mb-4">
    <div style="display: flex; justify-content: space-between;">
        <h5>{{'sender.dashboard.main_title' | translate}} </h5>
        <div>
            <div class="spinner-grow" role="status" *ngIf="isLoading">
            </div>
            <a mat-icon-button color="primary" [routerLink]="[]" (click)="refresh()">
                <mat-icon>refresh</mat-icon>
            </a>
        </div>
    </div>
</div>

<div class="container">

    <div class="container mt-4 mb-4 text-center" *ngIf="(usersService.ownUser$ | async)?.permission_new_shipments">
        <a style="width: max-content;" [routerLink]="['/main/shipper/wizard']" class="btn-primary green primary-hover-animation mx-auto">
            {{'sender.dashboard.shipment' | translate}}
        </a>
    </div>

</div>