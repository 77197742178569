import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { versions } from 'projects/versions';
import { LicenseModalComponent } from 'projects/webapp/app/components/license-modal/license-modal.component';
import { MessageModalComponent } from 'projects/webapp/app/components/message-modal/message-modal.component';
import { AuthService } from 'projects/webapp/app/services/auth.service';
import { IMessage, MessageService } from 'projects/webapp/app/services/message.service';
import { OrganizationsService } from 'projects/webapp/app/services/organizations.service';
import { IDetailedUser, UsersService } from 'projects/webapp/app/services/users.service';
import { deepCopy } from 'projects/webapp/app/utils';
import { lastValueFrom } from 'rxjs';


export interface MenuItem {
  title: string;
  icon: string;
  link: string;
  color: string;
  needsSubscription: boolean,
  expanded?: boolean;
  subMenu?: MenuItem[];
}

export type Menu = MenuItem[];


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  opened = true;
  myUser: IDetailedUser | null = null;
  @ViewChild('leftSideNav') public leftSideNav: any;
  menu: Menu = [
  ];
  versions = versions;
  messagesToShow: IMessage[] | null = []

  panelType: 'carrier' | 'driver' | 'shipper' | 'admin' | 'unknown' = 'unknown'

  constructor(
    public authService: AuthService,
    public usersService: UsersService,
    public organizationsService: OrganizationsService,
    private messageService: MessageService,
    private spinnerService: NgxSpinnerService,
    private router: Router,
    public translate: TranslateService,
    private dialog: MatDialog,
  ) {
    this.checkIfNeedsToCloseListsPanel(false);
    this.usersService.initService();
    this.organizationsService.initService();
  }

  async ngOnInit() {
    this.usersService.ownUser$.asObservable().subscribe(val => {
      this.myUser = val;
      this.refreshMenuItems()
    });
    this.router.events.subscribe((segs) => {
      this.refreshMenuItems()
    });

    this.messageService.getMessages().subscribe(val => {
      this.messagesToShow = [];
      this.messagesToShow = deepCopy(val);
      if (!!this.messagesToShow) {
        console.log(this.messagesToShow)
        this.showMessages();
      }
    });
  }

  showMessages() {
    if (this.messagesToShow && this.messagesToShow.length > 0) {
      for (let index = 0; index < this.messagesToShow.length; index++) {
        const element = this.messagesToShow[index];
        this.dialog.open(MessageModalComponent, { data: element }).afterClosed().subscribe(async val => {
          console.log('closing: ')
          console.log(val)
          await lastValueFrom(this.messageService.markAsViewed(element.uuid))
          if (val) {
            await lastValueFrom(this.messageService.markAsRead(element.uuid))
          }
        });
      }
    }
  }

  refreshMenuItems() {
    if (window.location.href.includes('/main/shipper')) {
      this.panelType = 'shipper'
      return this.refreshShipperMenuItems()
    }
    if (window.location.href.includes('/main/carrier')) {
      this.panelType = 'carrier'
      return this.refreshCarrierMenuItems()
    }
    if (window.location.href.includes('/main/driver')) {
      this.panelType = 'driver'
      return this.refreshDriverMenuItems()
    }
    if (window.location.href.includes('/main/admin')) {
      this.panelType = 'admin'
      return this.refreshAdminMenuItems()
    }
  }

  toggle(event: any): void {
    if (!event && this.opened) {
      this.opened = false;
    } else {
      this.opened = !this.opened;
    }
  }

  checkIfNeedsToCloseListsPanel(withOpen = false): void {
    setTimeout(() => {
      if (window.innerWidth < 701) {
        console.log('smaller than 700');
        // this.leftSideNav.close();
        this.opened = false;
      }
      if (withOpen) {
        this.opened = true;
        // this.leftSideNav.open();
      }
    }, 500);
  }

  private refreshShipperMenuItems() {
    if (!!this.myUser) {

      let menu: MenuItem[] = [
        {
          title: this.translate.instant('carrier_dashboard.menu.dashboard'),
          icon: 'dashboard',
          link: '/main/shipper/dashboard',
          color: '#59E4A6',
          needsSubscription: false,
        },
      ]

      if (
        this.myUser.permission_market_place &&
        this.organizationsService.ownOrg$.value?.have_market_place &&
        this.myUser.preference_show_market_place
      ) {
        menu.push(
          {
            title: this.translate.instant('carrier_dashboard.marketplace.main_title'),
            icon: 'place',
            link: '/main/shipper/market-place',
            color: '#59E4A6',
            needsSubscription: false,
          },
        )
      }

      menu.push(
        {
          title: this.translate.instant('carrier_dashboard.shipment_management'),
          icon: 'label',
          link: '/main/shipper/shipment-list',
          color: '#59E4A6',
          needsSubscription: false,
        },
      )

      menu.push(
        {
          title: this.translate.instant('carrier_dashboard.ads.main_title'),
          icon: 'wallpaper',
          link: '/main/shipper/adcard',
          color: '#59E4A6',
          needsSubscription: false,

        },
      )
      // menu.push(
      //   {
      //     title: 'Rampenverwaltung',
      //     icon: 'dock',
      //     link: '/main/shipper/dock-yard-management',
      //     color: '#59E4A6',
      //   },
      // )

      // if (this.myUser.permission_export) {
      //   menu.push(
      //     {
      //       title: 'Export',
      //       icon: 'import_export',
      //       link: '/main/shipper/export',
      //       color: '#59E4A6',
      //     },
      //   )
      // }

      menu.push(
        {
          title: this.translate.instant('common.search'),
          icon: 'search',
          link: '/main/shipper/search',
          color: '#59E4A6',
          needsSubscription: false,
        },
      )

      // if (this.myUser.permission_settings) {
      //   menu.push(
      //     {
      //       title: 'Einstellungen',
      //       icon: 'library_books',
      //       link: '/main/shipper/settings',
      //       color: '#59E4A6',
      //     },
      //   )
      // }

      this.menu = menu;
    }
  }

  private refreshCarrierMenuItems() {
    this.translate.get('common.language').subscribe((val) => {
      if (!!this.myUser) {

        let menu: Menu = [
          {
            title: this.translate.instant('carrier_dashboard.menu.dashboard'),
            icon: 'dashboard',
            link: '/main/carrier/dashboard',
            color: '#59E4A6',
            needsSubscription: false,
          }
        ]

        if (this.myUser.permission_dispatch) {
          menu.push(
            {
              title: this.translate.instant('carrier_dashboard.menu.import'),
              icon: 'label_important',
              link: '/main/carrier/import-shipments',
              color: '#59E4A6',
              needsSubscription: true,
            },
          )
        }

        if (this.myUser.permission_pipeline) {
          menu.push({
            title: this.translate.instant('carrier_dashboard.menu.release_pipeline'),
            icon: 'local_offer',
            link: '/main/carrier/pipeline-release',
            color: '#59E4A6',
            needsSubscription: true,
          })
        }

        if (this.myUser.permission_dispatch) {
          menu.push(
            {
              title: this.translate.instant('carrier_dashboard.menu.dispatch_pipeline'),
              icon: 'calendar_today',
              // link: '/main/carrier/pipeline-dispatch-v2',
              link: '/main/carrier/pipeline-dispatch',
              color: '#59E4A6',
              needsSubscription: true,

            },
          )
        }

        if (this.myUser.permission_invoicing) {
          menu.push(
            {
              title: this.translate.instant('carrier_dashboard.menu.facturation_pipeline'),
              icon: 'attach_money',
              link: '/main/carrier/pipeline-invoice',
              color: '#59E4A6',
              needsSubscription: true,

            },
          )
          if (this.myUser.preference_show_facturation) {
            menu.push(
              {
                title: this.translate.instant('carrier_dashboard.invoicing'),
                icon: 'account_balance',
                link: '/main/carrier/facturation',
                color: '#59E4A6',
                needsSubscription: true,

              },
            )
          }
        }

        if (
          this.organizationsService.ownOrg$.value?.have_market_place &&
          this.myUser.permission_market_place &&
          this.myUser.preference_show_shipments_to_pickup
        ) {
          menu.push(
            {
              title: this.translate.instant('carrier_dashboard.marketplace.main_title'),
              icon: 'place',
              link: '/main/carrier/market-place',
              color: '#59E4A6',
              needsSubscription: false,

            },
          )
          menu.push(
            {
              title: this.translate.instant('carrier_dashboard.ads.main_title'),
              icon: 'wallpaper',
              link: '/main/carrier/adcard',
              color: '#59E4A6',
              needsSubscription: false,

            },
          )
        }

        if (this.myUser.permission_client_management) {
          menu.push(
            {
              title: this.translate.instant('carrier_dashboard.customer_management'),
              icon: 'supervised_user_circle',
              link: '/main/carrier/clients',
              color: '#59E4A6',
              needsSubscription: true,

            },
          )
        }

        // if (this.myUser.permission_empties_management && this.organizationsService.ownOrg$.value?.have_empties_management) {
        //   menu.push(
        //     {
        //       title: 'Leergutverwaltung',
        //       icon: 'picture_in_picture_alt',
        //       link: '/main/carrier/empties',
        //       color: '#59E4A6',
        //     },
        //   )
        // }

        // if (this.myUser.permission_warehouse_management && this.organizationsService.ownOrg$.value?.have_warehouse_management) {
        //   menu.push(
        //     {
        //       title: 'Lagerverwaltung',
        //       icon: 'home',
        //       link: '/main/carrier/warehouse',
        //       color: '#59E4A6',
        //     },
        //   )
        // }

        if (this.myUser.permission_dispatch) {
          menu.push(
            {
              title: this.translate.instant('carrier_dashboard.shipment_management'),
              icon: 'label',
              link: '/main/carrier/shipment-list',
              color: '#59E4A6',
              needsSubscription: false,
            },
          )
        }

        // if (this.myUser.permission_dispatch) {
        //   menu.push(
        //     {
        //       title: 'Karten',
        //       icon: 'map',
        //       link: '/main/carrier/maps',
        //       color: '#59E4A6',
        //     },
        //   )
        // }

        if (this.myUser.permission_activity && this.myUser.preference_show_activity) {
          menu.push(
            {
              title: this.translate.instant('common.activity'),
              icon: 'remove_red_eye',
              link: '/main/carrier/activity',
              color: '#59E4A6',
              needsSubscription: true,

            },
          )
        }

        if (this.myUser.permission_export) {
          menu.push(
            {
              title: this.translate.instant('common.export'),
              icon: 'import_export',
              link: '/main/carrier/export',
              color: '#59E4A6',
              needsSubscription: true,

            },
          )
        }

        menu.push(
          {
            title: this.translate.instant('common.search'),
            icon: 'search',
            link: '/main/carrier/search',
            color: '#59E4A6',
            needsSubscription: false,

          },
        )

        if (this.myUser.permission_settings) {
          menu.push(
            {
              title: this.translate.instant('common.settings'),
              icon: 'library_books',
              link: '/main/carrier/settings/organization',
              color: '#59E4A6',
              needsSubscription: false,
            },
          )
        }
        this.menu = menu;
      };
    })
  }


  private refreshAdminMenuItems() {
    if (!!this.myUser) {
      let menu: any[] = [
        {
          title: this.translate.instant('carrier_dashboard.menu.dashboard'),
          icon: 'dashboard',
          link: '/main/admin/dashboard',
          color: '#59E4A6',
        },
      ]


      menu.push(
        {
          title: this.translate.instant('carrier_dashboard.organizations'),
          icon: 'add',
          color: '#888888',
          link: '/main/admin/organizations',
        }
      )

      menu.push(
        {
          title: this.translate.instant('common.users'),
          icon: 'add',
          color: '#888888',
          link: '/main/admin/users',
        }
      )

      menu.push(
        {
          title: this.translate.instant('carrier_dashboard.auctions'),
          icon: 'add',
          color: '#888888',
          link: '/main/admin/auctions',
        }
      )

      menu.push(
        {
          title: this.translate.instant('carrier_dashboard.transactions'),
          icon: 'add',
          color: '#888888',
          link: '/main/admin/transactions',
        }
      )

      menu.push(
        {
          title: this.translate.instant('common.shipments'),
          icon: 'add',
          color: '#888888',
          link: '/main/admin/shipments',
        }
      )

      menu.push(
        {
          title: this.translate.instant('carrier_dashboard.adcards'),
          icon: 'add',
          color: '#888888',
          link: '/main/admin/adcards',
        }
      )

      menu.push(
        {
          title: this.translate.instant('carrier_dashboard.shipment_search'),
          icon: 'search',
          link: '/main/admin/shipment-search',
          color: '#59E4A6',
        },
      )

      menu.push(
        {
          title: this.translate.instant('common.activity'),
          icon: 'add',
          color: '#888888',
          link: '/main/admin/activity',
        }
      )

      this.menu = menu;

    }
  }

  private refreshDriverMenuItems() {
    if (!!this.myUser) {
      let menu: any[] = [
        {
          title: this.translate.instant('carrier_dashboard.menu.dashboard'),
          icon: 'dashboard',
          link: '/main/driver/dashboard',
          color: '#59E4A6',
        },
      ]

      menu.push(
        {
          title: this.translate.instant('carrier_dashboard.for_pickup'),
          icon: 'add',
          color: '#888888',
          link: '/main/driver/shipments-assigned/pickup',
        }
      )

      menu.push(
        {
          title: this.translate.instant('driver_app.dashboard.delivery'),
          icon: 'add',
          color: '#888888',
          link: '/main/driver/shipments-assigned/delivery',
        }
      )

      menu.push(
        {
          title: this.translate.instant('carrier_dashboard.in_the_vehicle'),
          icon: 'add',
          color: '#888888',
          link: '/main/driver/shipments-assigned/inVehicle',
        }
      )

      menu.push(
        {
          title: this.translate.instant('carrier_dashboard.my_tours'),
          icon: 'add',
          color: '#888888',
          link: '/main/driver/tours',
        }
      )

      this.menu = menu;

    }
  }

  menuItemClicked(menuItem: MenuItem) {
    console.log(menuItem)
    if (menuItem.needsSubscription && !this.organizationsService.ownOrg$.value?.admin_permission_software_license) {
      this.dialog.open(LicenseModalComponent,
        { disableClose: true }
      ).afterClosed().subscribe(() => {
        this.router.navigate(['/main/carrier'])
      })
      return
    }
    this.checkIfNeedsToCloseListsPanel(false);
  }


  cancelLoading() {
    this.spinnerService.hide()
  }

}
