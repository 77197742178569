import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'projects/webapp/app/environments/environment';
import { PublicShipmentsService } from 'projects/webapp/app/services/public-shipments.service';
import { IShipment } from 'projects/webapp/app/services/shipments.service';
import { lastValueFrom } from 'rxjs';
import * as mapboxgl from 'mapbox-gl';
import { MapBoxService } from 'projects/webapp/app/services/mapbox.service';
import { ShipmentCommentModal } from 'projects/webapp/app/components/shipment-comment-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { downloadFile } from 'projects/webapp/app/utils';


@Component({
  selector: 'app-shipment-tracking-page',
  templateUrl: './shipment-tracking.component.html',
  styleUrls: ['./shipment-tracking.component.scss']
})
export class ShipmentTrackingPageComponent implements OnInit, AfterViewInit {

  trackingUUID: string | null = null;
  shipment: Partial<IShipment> | null = null;

  numberOfCargoItems = 0;

  isLoading = false;
  mapBoxId = 'tracking-map';
  map: any = null;

  lat = 46.776;
  lng = 7.56;

  style = 'mapbox://styles/neptundesign/cm046bxjh00f401qo7s408u06';

  @ViewChild('mapElement') mapElement: ElementRef | undefined;

  constructor(
    public publicShipmentsService: PublicShipmentsService,
    public route: ActivatedRoute,
    public mapboxService: MapBoxService,
    public dialog: MatDialog,
  ) {
  }

  async ngOnInit() {
    this.route.params.subscribe(val => {
      if (!!val && val['tracking_uuid']) {
        this.trackingUUID = val['tracking_uuid'];
        this.refreshShipment();
      }
    });
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      mapboxgl as typeof mapboxgl;
      this.map = new mapboxgl.Map({
        accessToken: environment.mapboxToken,
        container: ((this.mapElement as any).nativeElement),
        style: this.style,
        zoom: 6.326,
        minZoom: 5,
        // maxZoom: 20,
        center: [this.lng, this.lat],
      });
    }, 500);
  }

  async updateMarkers() {
    if (this.shipment?.pickup_address && this.shipment.pickup_zip) {
      const pickup = await lastValueFrom(this.mapboxService.getLocationByLocationNameAndPostCode(this.shipment?.pickup_address, this.shipment?.pickup_zip))
      console.log(pickup)
    }
    if (this.shipment?.delivery_address && this.shipment.delivery_zip) {
      const delivery = await lastValueFrom(this.mapboxService.getLocationByLocationNameAndPostCode(this.shipment?.delivery_address, this.shipment?.delivery_zip))
      console.log(delivery)
    }
  }

  async refreshShipment() {
    try {
      this.isLoading = true;
      if (this.trackingUUID) {
        this.shipment = await lastValueFrom(this.publicShipmentsService.getPublicShipmentDetails(this.trackingUUID))
        this.numberOfCargoItems = 0
        this.shipment.packets?.forEach(el => {
          this.numberOfCargoItems = + el.count
        })
        await this.updateMarkers()
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false;
    }
  }

  notImplementedYet() {
    throw new Error('Method not implemented.');
  }

  async onDownloadDeliverySlip() {
    if (this.trackingUUID && this.shipment) {
      try {
        this.isLoading = true;
        const response = await lastValueFrom(this.publicShipmentsService.getDeliverySlip(this.trackingUUID));
        const blob = new Blob([response], { 'type': 'application/pdf' });
        downloadFile(blob, `Lieferschein-${this.shipment.number}.pdf`);
      } catch (error) {
        console.error(error)
      } finally {
        this.isLoading = false;
      }
    }
  }

  onAddPublicComment() {
    this.dialog.open(ShipmentCommentModal).afterClosed().subscribe(async (val) => {
      if (!!val && this.trackingUUID) {
        await lastValueFrom(this.publicShipmentsService.addPublicShipmentComment(this.trackingUUID, val));
        this.refreshShipment();
      }
    })
  }
}
