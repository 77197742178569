<div class="container-fluid">
    <mat-card class="mt-4">
        <mat-card-content>

            <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <h1>{{'carrier_dashboard.invoicing.main-title' | translate}}</h1>
            </div>

            <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="center" class="mt-1 mb-1">
                <mat-tab [label]="'carrier_dashboard.invoicing.ready_for_facturation' | translate">
                    <div style="display: flex; flex-direction: row; justify-content: end;">

                        <div>
                            <button mat-icon-button color="primary" (click)="refresh()">
                                <mat-icon>refresh</mat-icon>
                            </button>
                        </div>
                    </div>

                    <div *ngIf="!!clientShipments && clientShipments.length == 0" class="alert alert-info mt-3">
                        {{'carrier_dashboard.invoicing.no-shipments' | translate}}
                    </div>

                    <table *ngIf="!!clientShipments && clientShipments.length > 0" style="width: 100%"
                        class="table table-custom">
                        <thead class="thead-light">
                            <td>{{'common.number' | translate}}</td>
                            <td>{{'common.price'| translate}}</td>
                            <td style="display: flex; justify-content: center;"> </td>
                        </thead>

                        <tbody>
                            <ng-container *ngFor="let client of clientShipments">
                                <!-- <pre>{{client | json}}</pre> -->
                                <tr class="table-success">
                                    <td>
                                        {{'common.client' | translate}}:
                                        <a routerLink="/main/carrier/clients/{{client.client_object.uuid}}/details">
                                            {{client.client_object.company}}
                                        </a>
                                    </td>
                                    <td>{{'common.total' | translate}} {{ownOrganization?.default_currency}} {{client.client_total_price | number: '1.2-2'}}</td>

                                    <td style="text-align: end;">

                                        <a class="green-btn primary-hover-animation justify-content-center d-flex ms-auto" [routerLink]="[]"
                                            (click)="invoiceClient(client.client_object)">
                                            {{'carrier_dashboard.facturation.generate_invoice' | translate}}
                                        </a>
                                        <!-- <a mat-icon-button >
                                            <mat-icon>arrow_right</mat-icon>
                                        </a> -->

                                    </td>

                                </tr>
                                <tr *ngFor="let shipment of client.shipments">
                                    <td [attr.data-label]="'common.number' | translate">
                                        <a [routerLink]="'/main/carrier/shipments/' + shipment.uuid">
                                            {{shipment.number | shipmentNumberPipe}}
                                        </a>
                                    </td>
                                    <td [attr.data-label]="'common.price' | translate">
                                        <div class="text-end">
                                            {{ownOrganization?.default_currency}} {{shipment.total_price | number: '1.2-2' }}
                                        </div>
                                    </td>
                                    <td style="text-align: end;">
                                        <a class="green-btn primary-hover-animation justify-content-center align-items-center d-flex ms-auto" mat-icon-button color="primary" [routerLink]="'/main/carrier/shipments/' + shipment.uuid">
                                            <mat-icon class="fs-sm-2">arrow_right</mat-icon>
                                            {{'common.details' | translate}}
                                        </a>
                                    </td>
                                </tr>
                            </ng-container>

                        </tbody>
                    </table>

                    <!-- <pre>{{clientShipments | json}}</pre> -->
                </mat-tab>
                <mat-tab [label]="'carrier_dashboard.facturation.open_invoices' | translate">
                    <ng-template matTabContent>
                        <app-accounting-invoice-list
                            [invoiceType]="[invoiceStatus.DRAFT, invoiceStatus.SENT]"></app-accounting-invoice-list>
                    </ng-template>
                </mat-tab>
                <mat-tab [label]="'carrier_dashboard.facturation.paid_invoices' | translate">
                    <ng-template matTabContent>
                        <app-accounting-invoice-list
                            [invoiceType]="[invoiceStatus.PAID, invoiceStatus.CANCELLED]"></app-accounting-invoice-list>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </mat-card-content>
    </mat-card>
</div>
