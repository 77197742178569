import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import * as Sentry from "@sentry/angular-ivy";
import { environment } from './app/environments/environment';
import { versions } from 'projects/versions';

if (environment.envName == 'prod') {

  Sentry.init({
    release: versions.hash,
    dsn: "https://24f8c9af91aa4eb18482f5ee4968d4ae@o330358.ingest.sentry.io/4505246781800448",
    integrations: [
      new Sentry.BrowserTracing({
        tracingOrigins: ['localhost:4200', 'https://app.cargoflow.ch'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      new Sentry.Replay(),
    ],
    environment: environment.envName,
    tracesSampleRate: 0.1,
    beforeSend: (event) => {
      const exception = event.exception?.values && event.exception?.values[0]
      if (exception?.value === 'Non-Error exception captured with keys: currentTarget, isTrusted, target, type') {
        return null;
      }
      return event;
    }
  });
}


platformBrowserDynamic().bootstrapModule(AppModule)
  .then(()=>{
    console.log(`Bootstrap success: ${versions.hash}, ${versions.date}`);
  })
  .catch(err => console.error(err));
