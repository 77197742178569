import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { CarrierShipmentsService } from 'projects/webapp/app/services/carrier-shipments.service';
import { IShipment } from 'projects/webapp/app/services/shipments.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-shipment-list',
  templateUrl: './shipment-list.component.html',
  styleUrls: ['./shipment-list.component.scss']
})
export class ShipmentListComponent implements OnInit {
  shipments: IShipment[] = [];
  isLoading = false;

  constructor(
    public carrierShipmentsService: CarrierShipmentsService,
    public dialog: MatDialog,
    private spinnerService: NgxSpinnerService,
  ) {
  }

  async ngOnInit() {
    this.refresh()
  }

  async refresh() {
    try {
      this.spinnerService.show()
      this.isLoading = true;
      this.shipments = await lastValueFrom(this.carrierShipmentsService.getShipments())
    } catch (error) {
      console.error(error)
    } finally {
      this.spinnerService.hide()
      this.isLoading = false;
    }
  }
}
