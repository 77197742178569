import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { InfoModalComponent } from 'projects/webapp/app/components/info-modal/info-modal.component';
import { IOrganization } from 'projects/webapp/app/interfaces/organization.interface';

import { AuthService } from 'projects/webapp/app/services/auth.service';
import { CarrierShipmentsService, IImportInformation } from 'projects/webapp/app/services/carrier-shipments.service';
import { ClientsService } from 'projects/webapp/app/services/clients.service';
import { FileHandle } from 'projects/webapp/app/services/dragDrop.directive';
import { OrganizationsService } from 'projects/webapp/app/services/organizations.service';
import { UsersService } from 'projects/webapp/app/services/users.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-import-shipments',
  templateUrl: './import-shipments.component.html',
  styleUrls: ['./import-shipments.component.scss']
})
export class ImportShipmentsComponent implements OnInit {

  ownOrganization: IOrganization | null = null;

  lastImport: IImportInformation = {
    shipments: [],
    created_at: ''
  };
  isLoading = false;
  files: FileHandle[] = []

  constructor(
    private title: Title,
    public authService: AuthService,
    public clientService: ClientsService,
    public carrierShipmentsService: CarrierShipmentsService,
    public usersService: UsersService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private toastrService: ToastrService,
    private translateService: TranslateService,
    private organizationService: OrganizationsService,
  ) {
    this.translate.get('carrier_dashboard.shipment_import.title').subscribe(val => {
      this.title.setTitle(val);
    });
  }

  async ngOnInit() {
    await this.refresh();
    this.ownOrganization = await this.organizationService.getOwnOrganization().toPromise() as any;
  }

  async refresh() {
    const result = await lastValueFrom(this.carrierShipmentsService.getTempImportShipments());
    console.log(result);
    if (!!result.shipments) {
      this.lastImport = result
    }
  }

  translateKey (key: string) {
    return this.translate.instant(key)
  }

  async onFileSelected(event: any) {
    const file: File = event.target.files[0];
    console.log(file)
    if (file) {
      const fileName = file.name;
      const formData = new FormData();
      formData.append("file", file);
      formData.append('name', fileName);
      try {
        const result = await lastValueFrom(this.carrierShipmentsService.importShipmentsFile(formData));
        console.log(result);
        this.lastImport = result
        if (result.shipments.length == 1) {
          const message = await lastValueFrom(this.translateService.get('toastr.shipment_import', { count: result.shipments.length }));

          this.toastrService.success(message)
        }
        if (result.shipments.length >= 2) {
          const message = await lastValueFrom(this.translateService.get('toastr.shipments_import', { count: result.shipments.length }));

          this.toastrService.success(message)
        }
      } catch (error: any) {
        console.error(error)
        if (error.details) {
          this.toastrService.error(error.details, 'Fehler')
        }
      }
    }
  }

  filesDropped(files: any): void {
    this.files = files as FileHandle[];

    if (this.files) {
      this.files.forEach(async file => {
        console.log(file)
        const fileName = file.name || '';
        const formData = new FormData();
        let blob = await fetch(file.data).then(r => r.blob());
        console.log(blob)
        formData.append("file", blob as any);
        formData.append('name', fileName);
        try {
          const result = await lastValueFrom(this.carrierShipmentsService.importShipmentsFile(formData));
          console.log(result);
          this.lastImport = result
          if (result.shipments.length == 1) {
            const message = await lastValueFrom(this.translateService.get('toastr.shipment_import', { count: result.shipments.length }));

            this.toastrService.success(message)
          }
          if (result.shipments.length >= 2) {
            const message = await lastValueFrom(this.translateService.get('toastr.shipments_import', { count: result.shipments.length }));

            this.toastrService.success(message)
          }
        } catch (error: any) {
          console.error(error)
          if (error.details) {
            this.toastrService.error(error.details, 'Fehler')
          }
        }
      });
    }
  }

  help() {
    this.dialog.open(InfoModalComponent, {
      data: {
        title: this.translate.instant("carrier_dashboard.shipment_import.help_title") ,
        html: this.translate.instant("carrier_dashboard.shipment_import.help_text", { link: '<a href="/assets/excel/import_shipments.xlsx">Link</a>' })
      }
    })
  }
}
