<div class="container-fluid">
    <mat-card class="mt-3 mb-3">
        <mat-card-content>
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <h1>{{'common.cards' | translate}}</h1>
                <button mat-icon-button color="primary" (click)="refresh()">
                    <mat-icon>refresh</mat-icon>
                </button>
            </div>

            <form [formGroup]="formGroup">
                <mat-checkbox formControlName="current_driver_locations">{{'common.current_driver_locations' | translate}}</mat-checkbox>
                <mat-checkbox
                    formControlName="current_shipments_pickup_locations">{{'common.current_shipments_pickup_locations' | translate}}</mat-checkbox>
                <mat-checkbox
                    formControlName="current_shipments_delivery_locations">{{'common.current_shipments_delivery_locations' | translate}}</mat-checkbox>
            </form>

        </mat-card-content>

    </mat-card>
</div>
<app-map [pins]="pins"></app-map>
