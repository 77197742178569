<h1 mat-dialog-title>
  <h3>{{'common.change_language' | translate}}</h3>
</h1>
<div mat-dialog-content>
  
  <app-language-changer></app-language-changer>

</div>
<div mat-dialog-actions style="display: flex; justify-content: space-evenly">
  <button class="btn-primary light primary-hover-animation" mat-dialog-close="">{{'common.close'|translate}}</button>
</div>
