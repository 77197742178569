<div class="container">
  <mat-card class="mt-3">
    <mat-card-content>
      <div style="display: flex; flex-direction: row; justify-content: space-between;">
        <h1>{{'sender.shipment_management.main_title' | translate}} </h1>
        <div style="display: flex; align-items: center;">
          <mat-spinner [diameter]="20" style="margin: 12px" *ngIf="isLoading"> </mat-spinner>
          <button mat-icon-button color="primary" (click)="refresh()">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
      </div>

      <div class="text-center mt-5" *ngIf="!isLoading && shipments.length == 0">
        <div class="alert alert-info">{{'common.no_shipment' | translate}}</div>
      </div>

      <table class="table table-custom" style="width: 100%" *ngIf="!!shipments && shipments.length > 0">
        <thead>
          <td>{{'common.number' | translate}}</td>
          <td>{{'common.from' | translate}} </td>
          <td>{{'common.to' | translate}} </td>
          <td>{{'common.status' | translate}} </td>
          <td>{{'common.contractor' | translate}} </td>
        </thead>

        <tbody>
          <tr *ngFor="let shipment of shipments">
            <td [attr.data-label]="'common.number' | translate">
              <a routerLink="/main/shipper/shipments/{{shipment.uuid}}">
                {{shipment.number | shipmentNumberPipe }}
              </a>
            </td>
            <td [attr.data-label]="'common.from' | translate">
              {{shipment.pickup_address }}
              <br>
              {{shipment.pickup_zip }} {{shipment.pickup_location }}
              <br>
              {{shipment.pickup_date }}
            </td>

            <td [attr.data-label]="'common.to' | translate">
              {{shipment.delivery_address }}
              <br>
              {{shipment.delivery_zip }} {{shipment.delivery_location }}
              <br>
              {{shipment.delivery_date }}
            </td>
            <td [attr.data-label]="'common.status' | translate">
              {{shipment.status | shipmentStatusPipe}}
            </td>
            <td [attr.data-label]="'common.contractor' | translate">
              <a [routerLink]="[]" (click)="openBuyerOrgDetails(shipment.buyer_object)" *ngIf="shipment.buyer_object">
                {{shipment.buyer_object.name }}
              </a>
              <span class="opacity-50" *ngIf="!shipment.buyer_object">{{'common.no_contractor' | translate}}</span>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- <pre>{{shipments | json}}</pre> -->

    </mat-card-content>
  </mat-card>
</div>
