<div class="page-container pb-4 mb-5">

  <div class="container mb-5">

    <mat-card class="mt-4">
      <mat-card-content>

        <div style="display: flex; flex-direction: row; justify-content: space-between;">
          <h1>{{'common.logo_upload' | translate}}</h1>
        </div>

        <div class="col-12 text-center" *ngIf="!!ownOrganizationLogo">
          <img [src]="ownOrganizationLogo.file" style="max-width: 250px;">
        </div>
        <br>
        <div class="col-12 text-center" *ngIf="!!ownOrganizationLogo">

          <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload>
          <button mat-icon-button color="primary" class="upload-btn" (click)="fileUpload.click()">
            <mat-icon>attach_file</mat-icon>
          </button>
        </div>
        <br>

        <div class="col-12 rmpm dropzone" *ngIf="!ownOrganizationLogo" appDrag (files)="filesDropped($event)">
          <div class="text-wrapper">
            <div class="text-center">
              {{'carrier_dashboard.user-editor-profile.document' | translate}}
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="mt-4 mb-4">
      <mat-card-content>

        <div style="display: flex; flex-direction: row; justify-content: space-between;">
          <h1>{{'carrier_dashboard.user-editor-profile.main_title' | translate}} </h1>
        </div>

        <div class="alert alert-info" *ngIf="ownOrganization && !ownOrganization.admin_permission_software_license">
          {{'carrier_dashboard.user-editor-profile.licensing' | translate}}
        </div>

        <form [formGroup]="organizationForm" (ngSubmit)="submitOrganization()" class="mt-4">

          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>{{'common.company_name' | translate}}</mat-label>
            <input matInput formControlName="company_name">
          </mat-form-field>

          <div class="row">

            <mat-form-field appearance="outline" class="col-6">
              <mat-label>{{'common.first_name' | translate}} </mat-label>
              <input matInput formControlName="first_name">
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-6">
              <mat-label>{{'common.last_name' | translate}}</mat-label>
              <input matInput formControlName="last_name">
            </mat-form-field>

          </div>
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>{{'common.mail' | translate}} </mat-label>
            <input matInput formControlName="email">
          </mat-form-field>

          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>{{'common.phone' | translate}}</mat-label>
            <input matInput formControlName="phone">
          </mat-form-field>


          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>{{'common.address' | translate}}</mat-label>
            <input matInput formControlName="address">
          </mat-form-field>

          <div class="row">

            <mat-form-field appearance="outline" class="col-6">
              <mat-label>{{'common.zip_code' | translate}}</mat-label>
              <input matInput formControlName="zip">
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-6">
              <mat-label>{{'common.location' | translate}}</mat-label>
              <input matInput formControlName="location">
            </mat-form-field>

          </div>

          <div class="row">
            <mat-form-field appearance="outline" class="col-6">
              <mat-label>{{'common.vat' | translate}}</mat-label>
              <input matInput formControlName="vat_number">
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-6">
              <mat-label>{{'common.iban' | translate}}</mat-label>
              <input matInput formControlName="iban_number">
            </mat-form-field>
          </div>

          <div class="row">

            <mat-form-field appearance="outline" class="col-6">
              <mat-label>{{'common.default_currency' | translate}}</mat-label>
              <mat-select formControlName="default_currency">

                <mat-option [value]="'CHF'">
                  CHF
                </mat-option>

                <mat-option [value]="'EUR'">
                  EUR
                </mat-option>

                <mat-option [value]="'PLN'">
                  PLN
                </mat-option>
              </mat-select>
            </mat-form-field>

          </div>

          <!-- <div class="row">
            <mat-checkbox formControlName="have_empties_management"> {{'carrier_dashboard.user-editor-profile.empties' |
              translate}} </mat-checkbox>
            <mat-checkbox formControlName="have_warehouse_management">
              {{'carrier_dashboard.user-editor-profile.warehouse' | translate}} </mat-checkbox>
            <mat-checkbox formControlName="have_market_place">{{'carrier_dashboard.user-editor-profile.marketplace' |
              translate}}</mat-checkbox>
          </div> -->

          <div class="d-flex justify-content-end">
            <button class="green-btn primary-hover-animation"> {{'common.save' | translate}} </button>
          </div>

        </form>
      </mat-card-content>
    </mat-card>

    <!-- <mat-tab label="Mein Abonnement" *ngIf="authService.isAdmin()">
                <ng-template matTabContent>
                    <mat-card>
                        <mat-card-content>

                            <div style="display: flex; flex-direction: row; justify-content: space-between;">
                                <h1>Abonnement der Organisation</h1>
                            </div>
                            <app-pricing-cards></app-pricing-cards>
                        </mat-card-content>
                    </mat-card>

                </ng-template>
            </mat-tab> -->

  </div>

</div>
