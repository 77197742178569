<mat-toolbar color="primary">
  <div class="logo-container">
    <button class="d-flex" [ngClass]="panelType == 'driver'? 'd-none d-sm-block': ''" (click)="menuToggled.emit(true)"
      mat-icon-button>
      <mat-icon class="fs-1 d-flex justify-content-center align-items-center">menu</mat-icon>
    </button>

    <button class="logo-btn" mat-button (click)="navigateToRoot();">
      <img src="assets/CragoFlow-Logo.png" style="height:43px; border-radius: 5px;" />
    </button>
  </div>

  <!-- <span class="spacer"></span> -->
  <!-- send the search query from layout to page -->
  <form class="search-bar" method="GET">
    <mat-icon style="font-size: 2rem; margin: 0 1rem; opacity: 0.5;">search</mat-icon>
    <input [(ngModel)]="searchTerm" (keyup.enter)="search()" type="text" name="query"
    [placeholder]="'common.main_search_bar' | translate">
  </form>

  <!-- <pre style="color: black;">{{usersService.ownUser$ | json}}</pre> -->
  <!-- <button mat-icon-button *ngIf="!(onlineService.onlineMode.asObservable() | async)">
        <mat-icon>signal_wifi_off</mat-icon>
    </button> -->
  <div class="d-flex align-items-center" style="margin-left: auto; display: flex;">
    <!-- TODO link to notifications -->
    <button mat-icon-button>
      <mat-icon style="color: lightgrey;" class="fs-3" *ngIf="notifications == 0">notifications_none</mat-icon>
      <mat-icon class="fs-3" *ngIf="notifications > 0">notifications_active</mat-icon>
    </button>
    <div class="welcome-text">
      <span>{{ userName }}</span>
      <!-- need to get the status from the back -->
      <span class="online" *ngIf="is_active">Online</span>
      <span *ngIf="!is_active">Offline</span>
    </div>
    <button class="avatar-container" [matMenuTriggerFor]="menu">

      <div class="d-flex justify-content-center">
        <ng-container *ngIf="myUser?.avatardocument_object?.file; else placeholder">
          <img [src]="myUser?.avatardocument_object?.file" alt="User Avatar" class="avatar-img">
        </ng-container>
        <ng-template #placeholder>
          <mat-icon class="fs-1 d-flex justify-content-center align-items-center">person</mat-icon>
        </ng-template>
      </div>
      <!-- <mat-icon class="fs-3">person</mat-icon> -->
    </button>
  </div>

  <mat-menu #menu>

    <button mat-menu-item (click)="navigateToProfile();">
      <span>{{'carrier_dashboard.my_profile' | translate}}</span>
    </button>

    <button mat-menu-item (click)="navigateToAdmin();" *ngIf="authService.isAuthenticatedPlatformAdmin()">
      <span>{{'carrier_dashboard.admin_view' | translate}}</span>
    </button>

    <button mat-menu-item (click)="navigateToCarrier();"
      *ngIf="(usersService.ownUser$ | async)?.permission_carrier_panel">
      <span>{{'carrier_dashboard.transporter_view' | translate}}</span>
    </button>

    <button mat-menu-item (click)="navigateToDriver();"
      *ngIf="(usersService.ownUser$ | async)?.permission_driver_panel">
      <span>{{'carrier_dashboard.driver_view' | translate}}</span>
    </button>

    <button mat-menu-item (click)="navigateToShipper();"
      *ngIf="(usersService.ownUser$ | async)?.permission_shipper_panel">
      <span>{{'carrier_dashboard.shipper_view' | translate}}</span>
    </button>

    <!-- <button mat-menu-item (click)="navigateToHelp();">
            <span>Hilfe / Support</span>
        </button> -->

    <button mat-menu-item (click)="navigateToChat();">
      <span>{{'common.live_chat' | translate}}</span>
    </button>

    <button mat-menu-item (click)="navigateToAbout();">
      <span>{{'main_header.about_cargoflow' | translate}}</span>
    </button>

    <button mat-menu-item (click)="openLanguageSelector();">
      <span>{{'main_header.change_language' | translate}}</span>
    </button>

    <button mat-menu-item (click)="logout();">
      <span>{{'common.logout' | translate}}</span>
    </button>
  </mat-menu>
</mat-toolbar>