<mat-card class="mt-3">
    <mat-card-content>
        <div style="display: flex; flex-direction: row; justify-content: space-between;">
            <h5>{{'common.images' | translate}}</h5>
            <div>
                <button mat-icon-button color="primary" (click)="refreshImages()">
                    <mat-icon>refresh</mat-icon>
                </button>
                <button mat-icon-button color="primary" (click)="onAddImage()">
                    <mat-icon>add_circle</mat-icon>
                </button>
            </div>
        </div>

        <ngx-spinner name="shipmentImages" [fullScreen]="false"></ngx-spinner>

        <div class="row">
            <div *ngFor="let image of shipmentImagesAfterType.pickup" class="col-xs-6 col-sm-6 col-md-4 col-lg-3">
                <div class="w-100" style="border: 1px solid black; border-radius: 5px; padding: 5px; margin: 2px">
                    <img [src]="image.file" width="100%" />
                    <small style="word-break: break-all;">{{'common.pickup' | translate}}: {{image.name}}</small>
                </div>
            </div>
            <div *ngFor="let image of shipmentImagesAfterType.delivery" class="col-xs-6 col-sm-6 col-md-4 col-lg-3">
                <div class="w-100" style="border: 1px solid black; border-radius: 5px; padding: 5px; margin: 2px">
                    <img [src]="image.file" width="100%" />
                    <small style="word-break: break-all;">{{'common.delivery' | translate}}: {{image.name}}</small>
                </div>
            </div>
            <div *ngFor="let image of shipmentImagesAfterType.general" class="col-xs-6 col-sm-6 col-md-4 col-lg-3">
                <div class="w-100" style="border: 1px solid black; border-radius: 5px; padding: 5px; margin: 2px">
                    <img [src]="image.file" width="100%" />
                    <small style="word-break: break-all;">{{image.name}}</small>
                </div>
            </div>
        </div>

        <!-- <pre>{{shipmentImagesAfterType | json}}</pre> -->

        <div class="alert alert-info" *ngIf="!shipmentImages || shipmentImages.length == 0">{{'common.no_images_yet' | translate}}
        </div>

    </mat-card-content>
</mat-card>