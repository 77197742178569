import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { OrganizationsService } from 'projects/webapp/app/services/organizations.service';
import { UsersService } from 'projects/webapp/app/services/users.service';
import * as mapboxgl from 'mapbox-gl';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, AfterViewInit {

  isLoading = false;
  map: any = null;

  lat = 46.776;
  lng = 7.56;

  style = 'mapbox://styles/neptundesign/cm046bxjh00f401qo7s408u06';

  @Input() pins: any[]= []

  @ViewChild('mapElement') mapElement: ElementRef | undefined;

  constructor(
    public organizationService: OrganizationsService,
    public usersService: UsersService,
    public dialog: MatDialog,
    public toastrService: ToastrService,
    private http: HttpClient,
  ) { }

  async ngOnInit() {
    return this.http
      .get<any>(`http://ip-api.com/json/`).subscribe(response => {
        this.lat = response.lat;
        this.lng = response.lon;
      })
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      mapboxgl as typeof mapboxgl;
      this.map = new mapboxgl.Map({
        accessToken: environment.mapboxToken,
        container: ((this.mapElement as any).nativeElement),
        style: this.style,
        zoom: 6.326,

        minZoom: 5,
        // maxZoom: 20,
        center: [this.lng, this.lat],
      });
      this.map.scrollZoom.disable();
      this.map.addControl(new mapboxgl.NavigationControl());

      for (let index = 0; index < this.pins.length; index++) {
        const marker = this.pins[index];
        new mapboxgl.Marker().setLngLat([marker.lat, marker.lng]).addTo(this.map);
      }

    }, 500);
  }

}
