import { Injectable } from '@angular/core';

import { MissingTranslationHandlerParams, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';


export class MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    console.error(`key not found for ${params.key}, ${params.translateService.currentLang}`)
  }
}


@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  public readonly supportedLanguages = ['en', 'de', 'pl']
  missingTranslationHandler = new MissingTranslationHandler()
  defaultBrowserLanguage = 'en'
  public get currentLanguage(): string {
    return this._language.value;
  }

  private _language: BehaviorSubject<string> = new BehaviorSubject<string>('en');

  constructor(
    private translate: TranslateService,
  ) {
    this.translate.missingTranslationHandler = this.missingTranslationHandler
  }

  init() {
    try {
      this.defaultBrowserLanguage = this._getBrowserLanguage();
      this.translate.addLangs(this.supportedLanguages);
      try {
        this.translate.setDefaultLang('en');
      } catch (error) {
        console.error(error)
      }
      const chosenLanguage = window.localStorage.getItem('cargoflow-language') || this.defaultBrowserLanguage
      this.changeLanguage(chosenLanguage)
    } catch (error) {
      console.error(error)
      this.changeLanguage('en')
    }
  }

  public changeLanguage(language: string): void {
    console.log(`going to change language to ${language}`)
    window.localStorage.removeItem('cargoflow-language');
    window.localStorage.setItem('cargoflow-language', language);
    this.translate.use(language)
    this._language.next(language)
  }

  private getPrimaryLanguage(tag: string) {
    if (tag.includes('-')) {
      return tag.split('-')[0];
    }
    return tag;
  }

  private _getBrowserLanguage(): string {
    console.log(window.navigator.language)
    console.log(window.navigator.languages)
    const foundLanguageWithCountry = window.navigator.languages.find((lang) => {
      const this_lang = this.getPrimaryLanguage(lang)
      const found = this.supportedLanguages.includes(this_lang);
      console.log(`lang; ${this_lang} -> ${found}`);
      return found;
    });

    if (!!foundLanguageWithCountry) {
      return this.getPrimaryLanguage(foundLanguageWithCountry)
    }
    return 'en'
  }

}
