import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'projects/webapp/app/services/auth.service';
import { ClientsService } from 'projects/webapp/app/services/clients.service';
import { UsersService } from 'projects/webapp/app/services/users.service';

@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss']
})
export class MapsComponent implements OnInit {

  pins = [
    {
      type: 'truck',
      link: 'asdfadsf',
      lat: 46.776,
      lng: 7.56
    }
  ]

  formGroup: FormGroup = new FormGroup({
    current_driver_locations: new FormControl(true),
    current_shipments_pickup_locations: new FormControl(false),
    current_shipments_delivery_locations: new FormControl(false),
  });

  constructor(
    public authService: AuthService,
    public clientService: ClientsService,
    public usersService: UsersService,
    public dialog: MatDialog,
  ) { }

  async ngOnInit() {
  }

  refresh(){
  }
}
