import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { versions } from 'projects/versions';
import { OrganizationsService } from 'projects/webapp/app/services/organizations.service';
import { IDashboardNumbers, ShipperShipmentsService } from 'projects/webapp/app/services/shipper-shipments.service';
import { UsersService } from 'projects/webapp/app/services/users.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-shipper-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class ShipperDashboardComponent implements OnInit {

  versions = versions;
  isLoading = false;

  // dashboardNumbers: IDashboardNumbers | null = null;

  constructor(
    private router: Router,
    private title: Title,
    private shipperShipmentService: ShipperShipmentsService,
    public organizationsService: OrganizationsService,
    public usersService: UsersService,
    private translateService: TranslateService,
  ) {
    this.translateService.get('common.dashboard').subscribe(val => {
      this.title.setTitle(`Cargoflow: ${val}`);
    });
  }

  async ngOnInit() {
    this.refresh()
  }
  
  async refresh() {
    try {
      this.isLoading = true;
      // this.dashboardNumbers = await lastValueFrom(this.shipperShipmentService.getDashboardNumbers())
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false;
    }
  }

  navigateToMarketPlace() {
    this.router.navigate(['/main/shipper/market-place'])
  }
}
